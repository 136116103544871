<template>
  <a-form-model-item label="选择备注">
    <a-radio-group @change="onChange">
      <a-radio-button v-for="item in reasons" :key="item.id" :value="item.reason">
        {{ item.reason }}
      </a-radio-button>
    </a-radio-group>
  </a-form-model-item>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "ReasonChoose",
  model: {
    prop: 'remark',
    event: 'change'
  },
  props: {
    remark: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['reasons'])
  },
  methods: {
    onChange (e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>

<style scoped>

</style>
