<template>
    <a-auto-complete
        :data-source="platePrefixDataSource"
        @search="platePrefixSearch"
        placeholder="请输入车牌"
        :value="plate"
        @change="onChange"
    />
</template>

<script>
export default {
  name: "PlateNumberInput",
  props: {
    plate: String,
    width: {
      type: Number,
      defaultValue: () => 100
    },
    placeholder: {
      type: String,
      defaultValue: () => '请输入车牌'
    }
  },
  data () {
    return {
      platePrefix: {
        'A': ['澳'],
        'C': ['川'],
        'E': ['鄂'],
        'G': ['贵','赣','甘','桂','港'],
        'H': ['沪','黑',''],
        'J': ['京', '津','冀','晋','吉','警'],
        'L': ['辽','鲁','领'],
        'M': ['闽','蒙'],
        'N': ['宁'],
        'Q': ['琼','青'],
        'S': ['苏','陕','使'],
        'W': ['皖'],
        'X': ['湘','新','学'],
        'Y': ['渝','豫','粤','云'],
        'Z': ['浙','藏'],
      },
      platePrefixDataSource: []
    }
  },
  methods: {
    platePrefixSearch (inputValue) {
      if (this.platePrefix[inputValue.toUpperCase()]) {
        this.platePrefixDataSource = this.platePrefix[inputValue.toUpperCase()];
      } else {
        this.platePrefixDataSource = [];
      }
    },
    onChange (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style scoped>

</style>