<template>
 <div>
   <a-form-model layout="inline" :model="form">
     <a-form-model-item label="车牌号码">
       <a-input size="small" v-model="form.plateNumber" style="width: 100px" :disabled="this.form.isNoLicenseCar"/>
     </a-form-model-item>
     <a-form-model-item label="入场时间起">
       <a-date-picker
           size="small"
           format="YYYY-MM-DD HH:mm:ss"
           :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
           v-model="form.enterStartTime"
           style="width: 100px"
       >
         <template slot="renderExtraFooter">
           <a-button size="small" @click="onClickQuickTime('start', 1, 'h')">1小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('start', 2, 'h')">2小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('start', 5, 'h')">5小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('start', 12, 'h')">12小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('start', 1, 'd')">1天前</a-button>
           <a-button size="small" @click="onClickQuickTime('start', 2, 'd')">2天前</a-button>
         </template>
       </a-date-picker>
     </a-form-model-item>
     <a-form-model-item label="入场时间止">
       <a-date-picker
           size="small"
           format="YYYY-MM-DD HH:mm:ss"
           :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
           v-model="form.enterEndTime"
           style="width: 100px"
       >
         <template slot="renderExtraFooter">
           <a-button size="small" @click="onClickQuickTime('end', 1, 'h')">1小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('end', 2, 'h')">2小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('end', 5, 'h')">5小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('end', 12, 'h')">12小时前</a-button>
           <a-button size="small" @click="onClickQuickTime('end', 1, 'd')">1天前</a-button>
           <a-button size="small" @click="onClickQuickTime('end', 2, 'd')">2天前</a-button>
         </template>
       </a-date-picker>
     </a-form-model-item>
     <a-form-model-item>
       <a-checkbox value="true" v-model="form.isNoLicenseCar" @change="onCheckedNoLicenseCar">只查无牌车</a-checkbox>
     </a-form-model-item>
     <a-form-model-item>
       <a-button size="small" type="primary" :loading="loading" @click="loadData">查询</a-button>
     </a-form-model-item>
   </a-form-model>
   <a-table
       :columns="columns"
       :row-key="(record, index) => index"
       :data-source="dataSource"
       :pagination="pagination"
       :loading="loading"
       @change="handleTabChange"
   >
     <template slot="name" slot-scope="name"> {{ name.first }} {{ name.last }} </template>
   </a-table>
 </div>
</template>

<script>
import moment from "moment";
import { queryEnterRecord } from '../../../api/opration'

const columns = [
  {
    title: '车牌号码',
    dataIndex: 'passPlate',
    key: 'passPlate'
  },
  {
    title: '通行时间',
    dataIndex: 'passTime',
    key: 'passTime'
  },
  {
    title: '通行通道',
    dataIndex: 'enterChannelName',
    key: 'channelName'
  },
  {
    title: '通行区域',
    dataIndex: 'areaName',
    key: 'areaName'
  },
  {
    title: '通行证',
    dataIndex: 'passportTypeName',
    key: 'passportTypeName'
  },
  {
    title: '车类型',
    dataIndex: 'enterCarTypeName',
    key: 'enterCarTypeName'
  }
];

export default {
  name: "EnterRecord",
  props: {
    currentParkingId: {
      type: String,
      value: undefined
    }
  },
  data() {
    return {
      columns,
      loading: false,
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        size: 'small',
        total: 0,
        showTotal: (total) => '共 ' + total + ' 条'
      },
      form: {
        plateNumber: '',
        enterStartTime: moment().subtract(10, 'h'),
        enterEndTime: moment(),
        isNoLicenseCar: false
      }
    }
  },
  methods : {
    moment,
    onClickQuickTime(target, value, unit) {
      if (target === 'start') {
        this.form.enterStartTime = moment().subtract(value, unit)
      } else {
        this.form.enterEndTime = moment().subtract(value, unit)
      }
    },
    handleTabChange (page) {
      this.pagination.current = page.current
      this.loadData()
    },
    loadData () {
      const params = { ...this.form }
      if (params.enterStartTime) {
        params.enterStartTime = params.enterStartTime.format('yyyy-MM-DD HH:mm:ss');
      }
      if (params.enterEndTime) {
        params.enterEndTime = params.enterEndTime.format('yyyy-MM-DD HH:mm:ss')
      }
      this.loading = true
      params.current = this.pagination.current
      params.size = this.pagination.pageSize
      queryEnterRecord({ ...params, parkingId: this.currentParkingId}).then(res => {
        this.pagination.total = res.total
        this.dataSource = res.records
      }).catch(e => {
        this.$message.error('查询失败：' + e.message)
      }).finally(() => {
        this.loading = false
      });
    },
    onCheckedNoLicenseCar () {
      this.form.plateNumber = ''
    }
  }
}
</script>

<style scoped>

</style>
