<template>
  <div>
    <a-empty v-if="dhCalls.length <= 0 && !isDhAnswering" style="margin-top: 10px" description="目前没有呼叫" />
    <a-card
        v-else
        v-for="(item, index) in dhCalls"
        :key="item.deviceNo"
        :hoverable="true"
        :bodyStyle="{ padding: '10px'}"
        style="margin-bottom: 5px">
      <div>
        {{ item.parkingName }} ({{ item.channelName }})
      </div>
      <a-row style="margin-top: 5px">
        <a-col :span="6" :offset="14">
          <a-button type="danger" size="small" shape="circle" icon="poweroff" @click="$emit('reject-dh-call', item.deviceNo, index)" />
        </a-col>
        <a-col :span="4">
          <a-button type="primary" size="small" shape="circle" icon="phone" @click="$emit('answer-dh-call', item.deviceNo, index)"/>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'DhCallItem',
  props: {
    dhCalls: {
      type: Array,
      default: ()=> []
    }
  },
  computed: {
    isDhAnswering () {
       return this.$store.getters.isDhAnswering
    }
  }
}
</script>

<style scoped>

</style>
