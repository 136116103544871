<template>
  <div>
    <img v-if="channelPicture" class="car_image" :src="channelPicture"/>
    <img v-else class="car_image" src="~@/assets/wtp.png">
  </div>
</template>

<script>
export default {
  props: {
    channelPicture: String,
    default: () => undefined
  },
  name: "Picture"
}
</script>

<style scoped>
  .car_image {
    width: 100%;
    height: 298px;
  }
</style>