<template>
  <div class="dh_container">
    <DhState :onLine="onLine" :state="state" :message="stateMessage"/>
    <div style="flex-grow: 1;">
      <DhCallItem v-if="!isDhAnswering" :dhCalls="dhCalls" @reject-dh-call="handlerRejectCall" @answer-dh-call="handlerAnswerDhCall"/>
      <div class="video">
        <video v-show="isDhAnswering" id="dhvideo" ref="dhvideo" autoplay style="height: 280px;"></video>
      </div>
      <a-row v-if="isDhAnswering" justify="space-around" align="middle" class="calling_opt">
        <a-col :span="10" :offset="1">{{ currentAnswer.parkingName }}({{ currentAnswer.channelName }})</a-col>
        <a-col :span="4">{{ talkSecond }}</a-col>
        <a-col :span="2">
          <a-button type="primary" size="small" icon="redo" @click="handlerReConnection(currentAnswer.deviceNo)" />
        </a-col>
        <a-col :span="2">
          <a-button size="small" icon="instagram" @click="handleCut(currentAnswer.deviceNo)" />
        </a-col>
        <a-col :span="4">
          <a-button type="danger" size="small" icon="stop" @click="handlerHanUpDhCalls(currentAnswer.deviceNo)" >挂断</a-button>
        </a-col>
      </a-row>
    </div>
    <a-alert
        v-if="isDhAnswering && !currentAnswer.channelId"
        :message="'设备编号：' + currentAnswer.deviceNo + '未关联配置通道'"
        banner
        closable
    />
    <a-modal
        title="截图保存"
        :visible="screenShotVisible"
        @cancel="screenShotVisible = false"
        :width="500"
        :bodyStyle="{ textAlign: 'center' }"
    >
      <img :src="screenShotSrc" style="height: 300px;width: 400px;" alt="视频截图"/>
      <template slot="footer">
        <a-button type="danger" @click="onScreenShotCancel">取消</a-button>
        <a-button icon="retweet" @click="handleCut">重截</a-button>
        <a-button type="primary" icon="save" :loading="uploadScreenshotBtnLoading" @click="onScreenShotSave">保存</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { uploadFile } from "../../../api/opration"
import DhCallItem from "./DhCallItem"
import moment from "moment"
import {mapActions, mapGetters } from "vuex"
import DhState from "./DhState"

export default {
  name: 'DhCall',
  components: {
    DhState,
    DhCallItem
  },
  data () {
    return {
      onLine: false,
      dhWeb: null,
      dhCalls: [],
      dhLoginHandle: null,
      talkTime: 0,
      IntervalTaskId: undefined,
      hanUpLoading: false,
      canvas: null,
      screenShotVisible: false,
      screenShotSrc: undefined,
      uploadScreenshotBtnLoading: false,
      autoLoginIntervalTaskId: undefined,
      state: 'info',
      stateMessage: undefined
    }
  },
  computed: {
    talkSecond: function () {
      const time = parseInt(this.talkTime / 60)
      const second = this.talkTime % 60
      return (time < 10 ? ('0' + time) : time) + ':' + (second < 10 ? '0' + second : second)
    },
    ...mapGetters(['currentAnswer', "isDhAnswering",'userInfo'])
  },
  mounted() {
    this.dhLogin()
    this.autoLoginIntervalTaskId = setInterval(() => {
      if (!this.dhLoginHandle) {
        this.dhLogin()
      }
    }, 5000);
  },
  destroyed() {
     clearInterval(this.autoLoginIntervalTaskId)
  },
  methods: {
    moment,
    ...mapActions([ 'addNewCall', 'answerDhCall', 'activeDhCarOwnHanUp', 'hanUpDhCall', 'rejectDhCall', 'activeDhCall', 'setHasDHCall', 'setDHDevices' ]),
    dhLogin () {
      console.info(this.userInfo)
      if (this.userInfo && this.userInfo.dihuUsername && this.userInfo.dihuPassword) {
        this.setState('info', '正在登录...')
        this.dhWeb.login(this.userInfo.dihuUsername, this.userInfo.dihuPassword, 'dh.hmzhtc.com');
      } else {
        this.setState('info', 'DH账号未配置')
        // this.$notification['warning']({
        //   message: '登录DH呼叫失败',
        //   description: 'DH用户信息未配置'
        // })
      }
    },
    dhLogout () {
      try {
        this.dhWeb.logout(this.dhLoginHandle);
      } catch (e) {
        console.error('登出异常')
        console.error(e)
      } finally {
        this.dhLoginHandle = undefined
        this.dhCalls = []
        this.onLine = false
      }
    },
    handlerAnswerDhCall (deviceNo, index) {
      const call = { ...this.dhCalls[index] }
      this.dhCalls.splice(index, 1)
      this.$notification.close(call.deviceNo)
      this.dhWeb.playRT(this.$refs.dhvideo, deviceNo, this.dhLoginHandle, true)
      this.answerDhCall(call).then(() => {
        this.$emit('handler-answer-dh-call')
        this.startTalkTime()
      })
    },
    handlerHanUpDhCalls(deviceNo) {
      this.handlerHanUpDhCall(deviceNo)
      this.$emit('close-dh-call')
    },
    handlerHanUpDhCall (deviceNo) {
      this.hanUpLoading = true
      try {
        console.info(deviceNo)
        console.info(this.dhLoginHandle)
        this.dhWeb.stopRT(deviceNo, this.dhLoginHandle)
      } catch (err) {
        this.$notification.error({
          message: '挂断DH呼叫失败',
          description: '请重新对讲后再挂断，设备编号：' + deviceNo ,
        })
        console.error('挂断笛虎呼叫异常：' + JSON.stringify(err))
      }
      this.hanUpDhCall().catch(e => {
        console.log('request 挂断呼叫 error: ' + JSON.stringify(e))
      }).finally(() => {
        this.stopTalkTime()
        this.hanUpLoading = false
      })
    },
    handlerActiveCall(deviceNo) {
      this.activeDhCall(deviceNo).catch(e => {
        console.info('主动向下呼叫 请求异常：'  + e)
      }).finally(() => {
        this.startTalkTime()
        this.dhWeb.playRT(this.$refs.dhvideo, deviceNo, this.dhLoginHandle, true)
      })
    },
    handlerRejectCall(deviceNo, index) {
      const call = { ...this.dhCalls[index] }
      this.rejectDhCall(call.id).catch(e => {
        this.$message.error('挂断呼叫时出现一个错误:' + e.message)
      }).finally(() => {
        this.$notification.close(call.deviceNo)
        this.dhCalls.splice(index, 1)
        this.dhWeb.playRT(this.$refs.dhvideo, deviceNo, this.dhLoginHandle, false)
        setTimeout(() => {
          this.dhWeb.stopRT(deviceNo, this.dhLoginHandle)
        }, 1500)
      })
    },
    handlerReConnection (deviceNo) {
      try {
        this.dhWeb.stopRT(deviceNo, this.dhLoginHandle)
      } catch (e) {
        this.setState('warn', '登出失败，可刷新页面重试。')
        console.error('stopRT 异常')
        console.error(e)
      }
      setTimeout(() => {
        this.dhWeb.playRT(this.$refs.dhvideo, deviceNo, this.dhLoginHandle, false)
        this.$notification.success({
          description: '重连成功',
          duration: 2,
          message: '提示',
          placement: 'bottomLeft'
        })
      },1000)
    },
    handleCut() {
      this.screenShotVisible = true
      this.$nextTick(() => {
        const video = this.$refs.dhvideo
        const canvas = document.createElement("canvas")
        canvas.width = video.videoWidth * 1
        canvas.height = video.videoHeight * 1
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
        this.screenShotSrc = canvas.toDataURL('image/png')
        this.canvas = canvas
      })
    },
    onScreenShotSave () {
      this.canvas.toBlob(blob => {
        let formData = new FormData()
        formData.append('parkingId', this.currentAnswer.parkingId)
        formData.append('callId', this.currentAnswer.id)
        formData.append('channelId', this.currentAnswer.channelId)
        formData.append('file', blob)
        this.uploadScreenshotBtnLoading = true
        uploadFile(formData).then(() => {
          this.screenShotVisible = false
          this.canvas = undefined
          this.screenShotSrc = undefined
          this.$message.success('截图保存成功，请在呼叫记录中查看')
        }).catch(e => {
          console.error('保存截图异常')
          console.error(e)
          this.$message.error('截图保存失败 ' + e.message)
        }).finally(() => {
          this.uploadScreenshotBtnLoading = false
        })
      })
    },
    onScreenShotCancel() {
      this.screenShotVisible = false
      this.screenShotSrc = undefined
    },
    initDh () {
      // eslint-disable-next-line no-undef
      this.dhWeb = new DHAlarmWeb()
      this.dhWeb.setWebsocketPort({ dataWsPort: 443, mediaWsPort: 443 })
      this.dhWeb.onNotify = this.onDhNotify
      this.dhWeb.onLogin = this.dhOnLogin
      this.dhWeb.onPlayRT = this.onDhPlayRT
      this.dhWeb.onAlarmServerClosed = this.onDhAlarmServerClosed
      this.dhWeb.onDeviceList = this.handlerDeviceList
    },
    dhOnLogin (data) {
      if(data.error === 'success') {
        this.$notification['success']({
          message: '登录DH成功',
          duration: 3
        })
        this.onLine = true
        this.dhLoginHandle = data.params.loginHandle
        this.setState('success', '登录DH成功')
      } else if (data.error === 'repeat') {
        this.$notification['error']({
          message: 'DH登录失败',
          description: '该坐席DH账号已经登录，不能重复登录',
          duration: null
        });
        this.setState('error', '登录DH失败，重复登录')
        return
      } else if(data.error == 'authfail'){
        // this.$notification['error']({
        //   message: 'DH登录失败',
        //   description: 'DH账号或密码错误',
        //   duration: null
        // });
        this.setState('error', '登录DH失败，账号或密码错误')
        return
      } else {
        this.$notification['error']({
          message: 'DH登录失败',
          duration: null
        });
        this.setState('error', '登录DH失败，未知错误')
        return
      }
    },
    onDhNotify (data) {
      const params = data.params;
      if (params.code === 'DeviceStatus') {
        if (params.action === 'Start') {
          const deviceNo = params.deviceId
          this.handlerCall(deviceNo)
        } else if(params.action === 'Dealing' || params.action === 'Normal') {
          const deviceNo = params.deviceId;
          this.$notification.close(deviceNo + '')
          /*
           即使是自己接了也会收到Dealing事件
          console.info(params.action)
          if (this.isDhAnswering) {
            if(deviceNo === Number.parseInt(this.currentAnswer.deviceNo)) {
              this.activeDhCarOwnHanUp(this.currentAnswer.id).catch(e => {
                console.log('客户主动挂断：' + e.message)
              })
            }
          }*/
          let cacheDhCalls = [ ...this.dhCalls ]
          this.dhCalls = cacheDhCalls.filter(item => {
            return Number.parseInt(item.deviceNo) !== deviceNo
          })
          this.setDHDevices(params)
        }
      }
    },
    handlerCall(deviceNo) {
      this.addNewCall(deviceNo).then(res => {
        this.dhCalls.push(res)
        this.$notification.open({
          message: res.parkingName + '(' + res.channelName + ')',
          description: '有新的呼叫 请及时处理',
          placement: 'bottomRight',
          icon: <a-icon type="phone" theme="twoTone" />,
          duration: 3,
          key: res.deviceNo
        })
      }).catch(() => {
        this.$message.error('新增呼叫异常')
        const callTime = moment.now()
        this.dhCalls.push({ parkingName: '未知停车场',channelName: '未知通道', deviceNo: deviceNo, callTime })
      })
    },
    handlerDeviceList (e) {
      this.setDHDevices(e.params.list[0])
    },
    onDhAlarmServerClosed () {
      this.setState('warn', '连接断开,5秒后将尝试自动登录')
      this.dhLogout()
    },
    onDhPlayRT (e) {
      if (e.error !== 'success') {
        this.$notification.error({
          placement: 'bottomLeft',
          message: '播放失败',
          description: '设备不在线或网络不佳',
          duration: null
        })
      }
    },
    onParseMsgError (e) {
      console.info(e)
    },
    startTalkTime () {
      this.talkTime = 0
      this.IntervalTaskId = self.setInterval(() => {
        this.talkTime += 1
      }, 1000)
    },
    stopTalkTime () {
      if (this.IntervalTaskId) {
        window.clearInterval(this.IntervalTaskId);
      }
      this.talkTime = 0
    },
    setState(state, sateMessage) {
      this.state = state
      this.stateMessage = sateMessage
    }
  },
  watch: {
    dhCalls: {
      deep: true,
      handler(newVal) {
        this.setHasDHCall(newVal && newVal.length > 0)
      }
    }
  },
  created() {
    this.initDh()
  }
}
</script>

<style scoped>
.dh_container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row_class {
  background-color: white;
  height: 30px;
}
.video {
  background-color: white;
  text-align: center;
}
.calling_opt {
  background-color: white;
  padding: 10px 0;
}
</style>
