<template>
  <a-card v-if="passport">
    <a-col :span="15">
      <a-descriptions size="small" :column="3" style="padding: 5px;margin-top: 5px;">
        <a-descriptions-item label="客户名称">
          {{ passport.clientName}}
        </a-descriptions-item>
        <a-descriptions-item label="通行证类型">
          {{ passport.passportTypeName }}
        </a-descriptions-item>
        <a-descriptions-item label="预付费类型">
          {{ translate(passport.prepaidType) }}
        </a-descriptions-item>
        <a-descriptions-item label="绑定车辆">
          {{ passport.enterTime }}
        </a-descriptions-item>
        <a-descriptions-item label="通行区域" :span="2">
          {{ passport.discountFee }}
        </a-descriptions-item>
        <a-descriptions-item label="有效期止" :span="3">
          {{ passport.startDate }} - {{ passport.stopDate }}
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-card>
  <a-card v-else>
    <span style="color: #CC9933">没有获取到通行证信息</span>
  </a-card>
</template>

<script>
export default {
  name: "Passport",
  props: {
    passport: {
      type: Object,
      default: () => undefined
    }
  },
  methods: {
    translate (val) {
      switch (val) {
        case 0:
          return '非充值类型'
        case 1:
          return '充值扣费'
        case 2:
          return '充值有效期限'
        case 3:
          return '充值扣次数'
        default:
          return '未知'
      }
    }
  }
}
</script>

<style scoped>

</style>