import request from "../utis/request";

const api =  {
    page: '/unattended/reg-plate-redress-record/page',
    redress: '/unattended/reg-plate-redress-record/redress'
}

export function getRedressPlateList (params) {
    return request({
        url: api.page,
        method: 'GET',
        params: params
    })
}

export function redressPlate (data) {
    return request({
        url: api.redress,
        method: 'POST',
        data
    })
}