<template>
  <div class="container">
    <TRTCState :on-line="this.onLine" />
    <CallItem v-if="!isHmAnswering"  :calls="calls" @answer-call="answerCall" @reject-call="rejectCall"/>
    <div v-if="isHmAnswering" class="answering">
      <div class="video_div" style="text-align: center">
        <div id="remote_stream" ref="remote_stream" style="height: 300px;display: flex; justify-content: center; align-items: center">
          <span v-if="this.currentAnswer.direction == 2 && this.streamLoding">
            呼叫中请稍后...
          </span>
        </div>
        <!-- <div id="local_stream" ref="local_stream" style="height: 200px;">
        </div> -->
      </div>
      <a-row class="calling_info" type="flex" justify="space-between" align="middle">
        <a-col :span="12" :offset="1"> {{ currentAnswer.parkingName }}({{ currentAnswer.channelName }})</a-col>
        <a-col :span="4">{{ talkSecond }}</a-col>
        <a-col :span="4">
          <a-button type="danger" size="small"  @click="hangUp" >挂断</a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import TRTC from 'trtc-js-sdk'
import CallItem from "./CallItem"
import {mapActions, mapGetters} from "vuex"
import { getUserSig } from "../../../api/hmCall"
import storage from "store"
import TRTCCalling from 'trtc-calling-js';
import TRTCState from "./TRTCState";
import moment from "moment"

export default {
  name: "TRTCCall",
  components: {
    TRTCState,
    CallItem
  },
  computed: {
    ...mapGetters(['currentAnswer', "isHmAnswering",'userInfo']),
    talkSecond: function () {
      const time = parseInt(this.talkTime / 60)
      const second = this.talkTime % 60
      return (time < 10 ? ('0' + time) : time) + ':' + (second < 10 ? '0' + second : second)
    },
  },
  data () {
    return {
      calls: [],
      onLine: false,
      talkTime: 0,
      IntervalTaskId: null,

      client: '',//客户端服务
      remoteStream: '',//远方播放流
      localStream: '',//本地流,
      sdkAppId: 1400523210,
      userSig: '',
      websocket: undefined,

      trtcCalling: undefined,
      streamLoding: false
    }
  },
  mounted() {
    this.getUserSig().then(() => {
      this.callingLogin()
      this.initCallingEvent()
    }).catch(e => {
      this.$notification.error({
        title: '红门呼叫登录失败',
        description: '获取用户签名错误：' + e.message
      })
    })
    if (!('WebSocket' in window)) {
      alert('当前浏览器 Not support websocket')
    }
    this.height = document.documentElement.clientHeight
  },
  watch: {
    calls: {
      deep: true,
      handler (val) {
        this.setHasPhoneCall(val && val.length > 0)
      }
    }
  },
  methods: {
    ...mapActions(['answerHmCall', 'hangUpHmCall', 'rejectHmCall','setHasPhoneCall', 'activeHmCall', 'setCallLock', 'lockAction']),
    moment,
    async callingLogin () {
      this.trtcCalling =  new TRTCCalling({
        SDKAppID: this.sdkAppId
      })
      await this.trtcCalling.login({
        userID: this.userInfo.userid + '',
        userSig: this.userSig
      })
    },
    call (deviceNo) {
      if (this.onLine) {
        console.info('device no :' + deviceNo)
        this.activeHmCall(deviceNo).catch(e => {
          this.$message.error('红门向下呼叫获取通道停车场信息异常：' + e.message)
        }).finally(() => {
          const callResult = this.trtcCalling.call({userID: deviceNo, type: TRTCCalling.CALL_TYPE.VIDEO_CALL})
          callResult.then(e => {
            console.warn('主动呼叫成功');
            console.warn(e)
            this.lockAction(deviceNo)
            this.startTalkTime();
            this.streamLoding = true
            this.trtcCalling.startLocalView({ 
              userID: this.userInfo.userid + '', videoViewDomID: 'local_stream' 
            })
          }).catch(e => {
            console.info('主动呼叫失败')
            console.warn(e)
          })
        })
      } else {
        this.$message.error('您不在线无法呼叫');
      }
    },
    goLine () {
      if (this.onLine) {
        if (this.websocket) {
          this.websocket.close();
        }
        this.calls = []
        this.onLine = false
      } else {
        let wsUrl = ''
        if(process.env.NODE_ENV  === 'production') {
          wsUrl = `wss://${location.host}/api/unattended/websocket/`
        }else{
          wsUrl = `ws://${location.host}/api/unattended/websocket/`
        }
        // const baseUrl = process.env.NODE_ENV  === 'production' ? 'wss://eduty.hmzhtc.com/api/unattended/websocket/' : 'ws://localhost:10082/unattended/websocket/'
        const baseUrl = wsUrl
        console.info(this.userInfo)
        this.websocket = new WebSocket(baseUrl + this.userInfo.userid + '?access-token=' + storage.get('token'))
        this.initWebSocket()
        this.onLine = true
      }
    },
    initWebSocket () {
      // 连接错误
      this.websocket.onerror = () => {
        console.error('WebSocket连接发生错误   状态码：' + this.websocket.readyState)
      }
      // 连接成功
      this.websocket.onopen = () => {
        console.debug('WebSocket连接成功    状态码：' + this.websocket.readyState)
      }
      // 收到消息的回调
      this.websocket.onmessage = this.setOnmessageMessage
      // 连接关闭的回调
      this.websocket.onclose = this.setOncloseMessage
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = this.onbeforeunload
    },
    setOnmessageMessage (event) {
      console.debug('服务端返回：' + event.data)
      const res = JSON.parse(event.data)
      if (res.type === 'CALLS') {
        this.calls = res.data
      } else if (res.type === 'ANSWER') {
        const deviceNo = res.deviceNo
        const cacheCalls = [...this.calls];
        this.calls = cacheCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
      } else if (res.type === 'HANGUP') {
        const deviceNo = res.deviceNo
        const cacheCalls = [...this.calls];
        this.calls = cacheCalls.filter(item => {
          return item.deviceNo !== deviceNo
        })
      } else if (res.type === 'CALL') {
        this.calls.push(res.data)
        new Notification('新的呼叫', {
          body: '您有新的呼叫等待处理',
        });
      } else if(res.type === 'LOGGED') {
        this.$notification['error']({
          message: '手机呼叫登录失败',
          description: '该坐席账号已经登录手机呼叫服务器，不能重复登录',
          duration: 3
        });
        this.onLine = false
      } else if(res.type === 'LOGIN_SUCCESSFUL') {
        this.$notification['success']({
          message: '手机登录成功',
          description: '成功连接手机呼叫服务器',
          duration: 3
        })
      } else if (res.type === 'PARKING_DISCONNECTION') {
        console.info(res)
        const data = res.Data
        this.$notification['warning']({
          message: data.parkingName + '断线线请及时处理',
          description: '断线时间：' + moment(data.connected_at).format('YYYY-MM-DD HH:mm:ss') + ' 联系人：' + data.contacts +' 联系电话：' + data.telephone,
          duration: null
        })
      }
      if ((res.type === 'CALLS' || res.type === 'CALL') && this.calls !== null && this.calls.length > 0){
        this.$emit('play-ring')
      }
    },
    setOncloseMessage () {
      console.error('WebSocket连接关闭    状态码：' + this.websocket.readyState)
      this.calls = []
      this.websocket = undefined
    },
    onbeforeunload () {
      this.onLine = false
      this.closeWebSocket()
    },
    closeWebSocket () {
      if (this.websocket) {
        this.websocket.close()
      }
    },
    startTalkTime () {
      this.talkTime = 0
      if(this.IntervalTaskId){
        clearInterval(this.IntervalTaskId)
      }
      this.IntervalTaskId = setInterval(() => {
        this.talkTime += 1
      }, 1000)
    },
    stopTalkTime () {
      if (this.IntervalTaskId) {
        window.clearInterval(this.IntervalTaskId);
      }
      this.talkTime = 0
    },
    //创建本地音视频流
    createStream (userId) {
      const localStream = TRTC.createStream({ userId, audio: true, video: true });
      this.localStream = localStream
      localStream
          .initialize()
          .catch(error => {
            console.error('初始化本地流失败 ' + error);
          })
          .then(() => {
            console.debug('初始化本地流成功');
            // 创建好后才能播放 本地流播放 local_stream 是div的id
            // localStream.play('local_stream');
            //创建好后才能发布
            this.publishStream(localStream, this.client)
          });
    },
    //发布本地音视频流
    publishStream (localStream, client) {
      client.publish(localStream)
          .catch(error => {
            console.error('本地流发布失败 ' + error);
          })
          .then(() => {
            console.debug('本地流发布成功');
          });
    },
    //订阅远端流--加入房间之前
    subscribeStream (client) {
      console.debug('订阅远端流')
      client.on('stream-added', event => {
        const remoteStream = event.stream;
        console.debug('远端流增加: ' + remoteStream.getId());
        client.subscribe(remoteStream, { audio: true, video: true }).then(() => {
          console.debug('订阅远端流成功')
        }).catch(e => {
          console.error('订阅远端流失败' + e)
        });
      });
    },
    //播放远端流
    playStream (client) {
      client.on('stream-subscribed', event => {
        const remoteStream = event.stream;
        console.debug('远端流订阅成功：' + remoteStream.getId());
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
        //做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
        this.$nextTick(() => {
          //播放
          remoteStream.play('remote_stream', { objectFit: 'contain'}).catch(e => {
            this.$notification.error({
              message: '播放车道音视频失败',
              description: '可能是车道设备离线或故障：' + e.message,
              duration: null
            })
          });
        })
      });
    },
    createClient () {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.sdkAppId,
        userId: this.userInfo.userid + '',
        userSig: this.userSig
      });
      this.client.on('peer-leave', event => {
        console.debug('远端用户退出事件' + event)
        this.leaveRoom()
        this.$notification['warning']({
          placement: 'topLeft',
          message: '提示',
          description: '车主挂断了呼叫'
        })
      });
    },
    answerCall(id) {
      const call = this.calls.find(item => item.id === id)
      this.answerHmCall(call).catch(e => {
        console.error('接听红门呼叫异常')
        console.error(e)
      }).finally(() => {
        this.$emit('handler-answer-dh-call')
        this.joinRoom(call.deviceNo)
        this.startTalkTime()
      })
    },
    //加入房间
    joinRoom (deviceNo) {
      this.createClient()
      this.subscribeStream(this.client)
      this.client
          .join({ roomId: parseInt(deviceNo) })
          .then(() => {
            console.debug('进房成功');
            //播放远端流
            this.playStream(this.client)
            //创建本地流
            this.createStream(this.userInfo.userid + '')
          }).catch(error => {
            console.error('进房失败 ' + error);
          });
    },
    //退出音视频
    leaveRoom () {
      this.client && this.client
          .leave()
          .then(() => {
            console.debug('退房成功')
            // 停止本地流，关闭本地流内部的音视频播放器
            this.localStream.stop();
            // 关闭本地流，释放摄像头和麦克风访问权限
            this.localStream.close();
            this.localStream = null;
            this.client = null
          }).catch(error => {
            // 错误不可恢复，需要刷新页面。
            console.error('退房失败 ' + error);
            window.location.reload()
          })
    },
    hangUp () {
      const currentAnswer = { ...this.currentAnswer }
      this.hangUpHmCall().finally(() => {
      this.lockAction(currentAnswer.deviceNo)
      this.setCallLock(currentAnswer.deviceNo)
      this.leaveRoom()
      this.stopTalkTime()
      this.trtcCalling.hangup()
      this.offCallingEvent()
      setTimeout(() => {
        this.callingLogin();
        this.initCallingEvent();          
      }, 1000);
     })
    },
    getUserSig () {
      return new Promise((resolve, reject) => {
        getUserSig().then((res) => {
          this.userSig = res
          resolve()
        }).catch(e => {
          this.$notification.open({
            message: '连接呼叫中心失败，请联系研发同事。',
            description: e.message,
            icon: <a-icon type="close-circle" style="color: red" />
          });
          reject()
        })
      })
    },
    rejectCall(id) {
      const call = this.calls.find(item => item.id === id)
      this.rejectHmCall(call).catch(e => {
        console.error('拒接红门呼叫异常')
        console.error(e)
      }).finally(() => {
        this.calls = this.calls.filter(item => {
          item.id !== id
        })
      })
    },
    initCallingEvent () {
      this.trtcCalling.on(TRTCCalling.EVENT.ERROR, (e) => {
        console.error('calling 异常')
        console.warn(e)
      })
      this.trtcCalling.on(TRTCCalling.EVENT.REJECT, (e) =>  {
        console.warn('被邀用户拒绝')
        console.warn(e)
        this.$message.error({
          title: '设备拒绝了通话'
        })
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.NO_RESP, (e) =>  {
        console.warn('被邀用户没有应答')
        console.warn(e)
        this.$notification.error({
          placement: 'topLeft',
          message: '设备忙线中',
          description: '无法接通，可稍后重试'
        })
      });
      this.trtcCalling.on(TRTCCalling.EVENT.LINE_BUSY, (e) =>  {
        console.warn('被邀用户忙线')
        console.warn(e)
        this.$message.error({
          title: '设备正在通话中，请稍后再试'
        })
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.CALL_END, (e) => {
        console.warn('通话结束')
        console.warn(e)
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_ENTER, (e) => {
        console.warn('用户加入房间')
        console.warn(e)
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_VIDEO_AVAILABLE, (e) => {
        console.warn('远端用户开启/关闭了摄像头')
        console.warn(e)
        this.trtcCalling.startRemoteView( { userID: this.currentAnswer.deviceNo, videoViewDomID: 'remote_stream' })
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_AUDIO_AVAILABLE, (e) => {
        console.warn('远端用户开启/关闭了麦克风')
        console.warn(e)
        this.streamLoding = false
      });
    },
    offCallingEvent () {
      if (this.trtcCalling) {
        this.trtcCalling.off(TRTCCalling.EVENT.ERROR)
        this.trtcCalling.off(TRTCCalling.EVENT.INVITED)
        this.trtcCalling.off(TRTCCalling.EVENT.REJECT);
        this.trtcCalling.off(TRTCCalling.EVENT.NO_RESP);
        this.trtcCalling.off(TRTCCalling.EVENT.LINE_BUSY);
        this.trtcCalling.off(TRTCCalling.EVENT.CALL_END);
        this.trtcCalling.off(TRTCCalling.EVENT.USER_ENTER);
        this.trtcCalling.off(TRTCCalling.EVENT.USER_AUDIO_AVAILABLE);
        this.trtcCalling.logout()
        this.trtcCalling = undefined
      }
    }
  },
  created() {
    setInterval(() => {
      if (this.onLine) {
        if(!this.websocket || this.websocket.readyState !== 1) {
          this.goLine()
        } else {
          this.websocket.send(JSON.stringify({type: 'HEARTBEAT' }))
        }
      } else {
        this.goLine()
      }
    }, 1000 * 5)
  },
  destroyed () {
    this.offCallingEvent()
    this.closeWebSocket()
  }
}
</script>

<style scoped>
 .container {
   height: 100%;
   display: flex;
   flex-direction: column;
 }

 .trtc_heard {
   background-color: white;
   height: 30px;
 }

 .answering {
   flex: 1;
   display: flex;
   flex-direction: column;
 }

 .calling_info {
   padding: 8px 0;
   background-color: white;
 }

 .video_div {
   flex: 1;
   background-color: white;
 }
</style>
