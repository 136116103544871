<template>
  <div>
    <div class="missed-call" style="color: #fff;" @click="showModal">
      <span>未接来电</span>
      <a-modal v-model="visible" width="1000px" title="未接来电" @cancel="closeModal">
        <template slot="footer">
          <a-button @click="closeModal">关闭</a-button>
        </template>
        <a-form-model style="margin-bottom: 10px;" ref="queryForm" layout="inline">
          <a-form-model-item label="停车场">
            <a-tree-select
              v-model="params.parkingId"
              :treeData="parkings"
              treeNodeFilterProp='title'
              :show-search="true"
              style="width: 200px"
              :dropdown-style="{ maxHeight: '500px', overflow: 'auto' }"
              placeholder="请选择要操作的停车场"
              allow-clear
              tree-default-expand-all/>
          </a-form-model-item>
          <a-form-model-item label="呼叫时间">
            <a-space>
              <a-input-number :parser="(value) => value && !isNaN(Number(value)) ? value : 1" :min="1" :max="9999999" v-model="params.time" style="width: 80px;" placeholder="时长" />
              <a-select  v-model="params.unit" style="width: 80px; margin-left: 5px;">
                <a-select-option :value="1">分钟</a-select-option>
                <a-select-option :value="2">小时</a-select-option>
              </a-select>
              <span>之内</span>
            </a-space>
          </a-form-model-item>
          <a-form-model-item>
            <a-space>
              <a-button type="primary" @click="query" :loading="btnLoading">查询</a-button>
              <a-button @click="reset" :loading="resetLoading">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
        <a-table 
          :columns="columns" 
          :data-source="data"
          :loading="loading"
          row-key="id"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template slot="operation" slot-scope="record">
            <img @click="callReturnAction(record)" :src="callReturn" alt="回拨" class="call-return">
          </template>
          <template slot="answer" slot-scope="answer">
            <div v-if="answer === true">
              <a-badge status="success" />
              <span>是</span>
            </div>
            <div v-if="answer === false">
              <a-badge status="warning" />
              <span>否</span>
            </div>
          </template>
        </a-table>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { missedCall } from '@/api/hmCall'
import { mapGetters } from 'vuex'
import pinyin from 'js-pinyin'
import callReturn from '@/assets/callReturn.svg'
import { mapActions } from 'vuex'

const columns = [
  {
    title: '停车场',
    dataIndex: 'parkingName'
  },
  {
    title: '方向',
    dataIndex: 'direction',
    customRender(direction){
      const directionMap = {
        1: '入口',
        2: '出口'
      }
      return directionMap[direction]
    }
  },
  {
    title: '通道名称',
    dataIndex: 'channelName'
  },
  {
    title: '呼叫时间',
    dataIndex: 'callTime'
  },
  {
    title: '是否接听',
    dataIndex: 'answer',
    scopedSlots: { customRender: 'answer' }
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  data(){
    return {
      callReturn,
      columns,
      visible: false,
      data: [],
      loading: false,
      pagination: {
        showTotal: (total) => '共 ' + total + ' 条',
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
      },
      params: {
        parkingId: undefined,
        time: 60,
        unit: 1
      },
      btnLoading: false,
      resetLoading: false,
    }
  },
  watch: {
    visible(nv){
      if(nv){
        this.fetch()
      }
    }
  },
  computed:{
    ...mapGetters(['dutyParking']),
    parkings(){
      if(!this.dutyParking?.length) return
      return this.dutyParking.map((p) => {
        return {
          title: p.name + '(' + pinyin.getCamelChars(p.name) + ')',
          value: p.parkingId
        }
      })
    }
  },
  methods: {
    ...mapActions(['callExecute']),
    query(){
      this.btnLoading = true
      this.fetch()
    },
    reset(){
      this.resetLoading = true
      this.params = {
        parkingId: undefined,
        time: 60,
        unit: 1
      }
      this.fetch()
    },
    closeModal(){
      this.visible = false
    },
    handleTableChange (pagination) {
      this.pagination = { ...pagination }
      this.fetch()
    },
    showModal(){
      this.visible = true
    },
    fetch(){
      const paramters = { 
        parkingId: this.params.parkingId, 
        offset: this.pagination.current,
        limit: this.pagination.pageSize
      }
      if(this.params.time && this.params.unit){
        paramters.passedTime = this.params.time * (this.params.unit === 1 ? 60 : 3600)
      }
      this.loading = true
      missedCall(paramters).then(res => {
        if(res.records){
          this.data = res.records
          this.pagination = {
            ...this.pagination,
            total: Number(res.total)
          }
        }else{
          this.data = []
          this.pagination = {
            ...this.pagination,
            total: 0
          }
        }
      }).finally(() => {
        this.loading = false
        this.btnLoading = false
        this.resetLoading = false
      })
    },
    callReturnAction(record){
      this.callExecute(record.deviceNo).then(res => {
        if(res){
          this.$emit('reCall', {
            deviceNo: record.deviceNo,
            parkingId: record.parkingId,
            channelId: record.channelId,
          })
          this.visible = false
        }
      })
      
    }
  }
}
</script>

<style scoped>
.missed-call{
  cursor: pointer;
}
.call-return{
  cursor: pointer;
}
.call-return:hover{
  transform: scale(1.1);
}
</style>