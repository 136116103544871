<template>
  <a-row class="trtc_heard" type="flex" justify="space-around" align="middle" :style="{ backgroundColor: onLine ? 'white' : 'red'}">
    <a-col :span="4">红门呼叫</a-col>
    <a-col :span="8">
      <template v-if="!onLine">
        连接中...
      </template>
      <template v-else>
        <span :style=" { color: onLine && '#00CC33' }"> {{ onLine ? '登录红门呼叫成功' : '连接中...' }}</span>
      </template>
    </a-col>
    <a-col :span="3">
      <a-icon v-if="onLine" type="customer-service" theme="twoTone" two-tone-color="#52c41a" />
      <a-icon v-if="!onLine" type="coffee" />
      <span>{{ onLine ? '在线' : '离线' }}</span>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "TRTCState",
  props: {
    onLine: {
      type: Boolean,
      defaultValue: () => false
    }
  }
}
</script>

<style scoped>
  .trtc_heard {
    background-color: white;
    height: 30px;
  }
</style>
