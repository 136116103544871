<template>
  <a-layout-header class="header">
    <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;height:100%">
      <div style="display: flex;align-items: center">
        <img class="log" src="~@/assets/hm_logo.png"/>
        <span style="color: white;font-size: 12px;font-weight: bold">红轻停云坐席</span>&ndash;&gt;
      </div>
      <a-space style="display: flex; align-items: center;">
        <Missedcall @reCall="(info) => $emit('reCall', info)" />
        <parking-status />
        <RedressPlate ref="redressPlate" />
        <a-dropdown style="margin-left: 10px">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="setReason"> <a-icon type="swap" />放行理由设置</a-menu-item>
            <a-menu-item key="2" @click="logout"> <a-icon type="logout" />退出登录</a-menu-item>
          </a-menu>
          <a-button type="link" style="margin-left: 8px;color: white;background-color: #001529">
              <span v-if="userInfo">
                {{ userInfo.username }}
              </span>
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </a-space>
    </div>
    <a-drawer
        title="放行理由设置"
        :maskClosable="false"
        :width="600"
        :visible="reasonVisible"
        :body-style="{ paddingBottom: '80px' }"
        @close="reasonVisible = false; editReason = null"
    >
      <div style="margin: 10px;text-align: right">
        <a-button type="primary" v-if="editReason === null" @click="editReason = {seq: 1}">新增</a-button>
      </div>
      <a-table
          size="small"
          :bordered="false"
          :pagination="false"
          :columns="reasonColumns"
          rowKey="id"
          :data-source="reasons">
          <span slot="action" slot-scope="text, record">
            <a @click="modifyReason(record.id)">编辑</a>
            <a-divider type="vertical" />
            <a @click="deleteReason(record.id)">删除</a>
          </span>
      </a-table>
      <div v-if="editReason" style="margin-top: 20px;padding:10px;border: 1px solid #e8e8e8">
        <a-form-model
            layout="horizontal"
            :model="editReason"
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item label="序号">
            <a-input-number :min="1" :max="100000" v-model="editReason.seq" />
          </a-form-model-item>
          <a-form-model-item label="放行理由">
            <a-input v-model="editReason.reason" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 10, offset: 3 }">
            <a-button type="primary" @click="saveReason" :loading="saveReasonLoading">保存</a-button>
            <a-button @click="editReason = null" style="margin-left: 10px">取消</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-drawer>
  </a-layout-header>
</template>

<script>
import { Modal } from 'ant-design-vue'
import {mapGetters} from "vuex";
import RedressPlate from "./RedressPlate";
import ParkingStatus from "./ParkingStatus";
import Missedcall from './Missedcall.vue'


const reasonColumns = [
  {
    title: '序号',
    dataIndex: 'seq',
    key: 'seq',
  },
  {
    title: '理由',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: "Head",
  components: {ParkingStatus, RedressPlate, Missedcall},
  data () {
    return {
      reasonColumns,
      reasonVisible: false,
      editReason: null,
      saveReasonLoading: false
    }
  },
  computed: {
    ...mapGetters(['reasons', 'userInfo'])
  },
  mounted() {
    this.$store.dispatch('getReason')
  },
  methods: {
    onRedress () {
      this.$refs.redressPlate.loadRedressPlate()
      this.redressPlateVisible = true
    },
    setReason () {
      this.reasonVisible = true
    },
    modifyReason (id) {
      this.editReason = { ...this.$store.getters.getReasonById(id) }
    },
    saveReason() {
      this.$store.dispatch('updateReason', this.editReason).then(() => {
        this.$message.success('保存放行理由成功')
        this.editReason = null
      })
    },
    deleteReason (id) {
      this.$confirm({
        title: '提示',
        content: '确定删除此放行理由吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          return this.$store.dispatch('deleteReason', id)
        }
      });
    },
    logout () {
      Modal.confirm({
        title: '确定退出登录吗?',
        onOk: ()=> {
          return this.$store.dispatch('Logout').then(() => {
              this.$router.push({ name: 'Login' })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .header {
    width: 100%;
    height: 50px;
  }
  .ant-layout-header{
    line-height: 0px;
    padding: 0 5px 0 20px;
  }
  .log {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  .heard_right {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }
</style>