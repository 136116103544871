<template>
  <div class="status_panel_container">
    <div class="dh_device_line_title">在线设备({{ getDhOnLineDevices.length }}个)</div>
    <div class="dh_device_list">
      <DhDeviceItem v-for="item in getDhOnLineDevices" :key="item.deviceId" :device="item"/>
    </div>
    <div class="dh_device_line_title">离线设备({{ getDhOfflineDevice.length }}个)</div>
    <div class="dh_device_list">
      <DhDeviceItem v-for="item in getDhOfflineDevice" :key="item.deviceId" :device="item"/>
    </div>
  </div>
</template>

<script>
import DhDeviceItem from "../DH/DhDeviceItem";
import { mapGetters } from "vuex";

export default {
  name: "DeviceStatusPanel",
  components: {
    DhDeviceItem
  },
  computed: {
    ...mapGetters(['getDhDevices', 'getDhOnLineDevices', 'getDhOfflineDevice']),
  }
}
</script>

<style scoped>
 .status_panel_container {
   padding: 10px;
   width: 100%;
   overflow-y: scroll;
 }

 .status_panel_container::-webkit-scrollbar {/*滚动条整体样式*/
   width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
   height: 4px;

 }

 .status_panel_container::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
   border-radius: 5px;
   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
   background: rgba(0,0,0,0.2);
 }

 .status_panel_container::-webkit-scrollbar-track {/*滚动条里面轨道*/
   -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
   border-radius: 0;
   background: rgba(0,0,0,0.1);
 }

 .dh_device_line_title {
   padding: 10px;
 }

 .dh_device_list {
   display: flex;
   flex-wrap: wrap;
 }
</style>
