import request from "../utis/request";

const api = {
  list: '/project/parking-configure/list'
}

export function getConfigureList (params) {
  return request({
    url: api.list,
    method: 'GET',
    params: params
  })
}

export function getFrpDomain (parkingId) {
  return request({
    url: '/project/parking-configure/' + parkingId + '/frp/url'
  })
}
