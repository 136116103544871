<template>
  <div v-if="device" class="dh_device_item">
    <img v-if="device.action === 'Normal'" src="~@/assets/device_online.png" />
    <img v-else src="~@/assets/device_offline.png" />
    <div class="info">
      <div>{{ device.deviceName }}</div>
      <div class="detail">
        <span> {{ device.deviceId }} </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: 'DhDeviceItem',
  props: {
    device: {
      type: Object,
      defaultValue: () => undefined
    }
  },
  methods: {
    moment
  }
}
</script>

<style scoped>
 .dh_device_item {
   width: 210px;
   height: 50px;
   padding: 5px;
   margin: 5px;
   border: 1px solid  gainsboro;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
 }

 .dh_device_item img {
   width: 40px;
   height: 32px;
 }

 .dh_device_item .info {
   margin-left: 5px;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   font-size: 12px;
 }

 .dh_device_item .info .detail {
   width: 100%;
   display: flex;
   justify-content: space-between;
 }
</style>
