import request from "../utis/request";

const parking = {
    list : '/project/system/user/managed-parking',
    channelTree: '/project/project/parking-channel/tree/',
    getChannelByDihuDeviceNo: '/project/project/parking-channel/get-dh/',
    channelAttr: '/project/project/parking/parking-channel-attr/get/',
    monitorAddress: '/unattended/ys/get-monitor-address/',
    getParking: '/project/project/parking/',
    userParkingTree: '/project/system/user/managed-parking-tree'
}

export function getParkingList(){
    return request({
        url: parking.list,
        method: 'get'
    })
}

export function getUserParkingTree() {
    return request({
        url: parking.userParkingTree,
        method: 'get'
    })
}

export function getChannelTree(parkingId){
    return request({
        url: parking.channelTree + parkingId,
        method: 'get'
    })
}

export function getChannelByDihuDeviceNo(dihuDeviceNo) {
    return request({
        url: parking.getChannelByDihuDeviceNo +  dihuDeviceNo,
        method: 'get'
    })
}

export function getMonitorAddress (monitorDeviceNo, monitorDevicePwd) {
    return request({
        url: parking.monitorAddress +  monitorDeviceNo + '/' + monitorDevicePwd,
        method: 'get'
    })
}

export function getParking(cloudParkingId) {
    return request({
        url: parking.getParking + cloudParkingId,
        method: 'GET'
    })
}

export function getChannelAttr(channelId, val) {
    return request({
        url: parking.channelAttr + channelId + '/' + val,
        method: 'GET'
    })
}
