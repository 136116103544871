<template>
    <a-modal
        title="手动放行"
        :maskClosable="false"
        :visible="manualPassVisible"
        okText="确定"
        cancelText="取消"
        @ok="manualPassOk"
        @cancel="onCancel"
        :confirmLoading="confirmLoading"
    >
      <a-form-model
          layout="horizontal"
          ref="ruleForm"
          :rules="rules"
          :model="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="放行通道" ref="channelId" prop="channelId">
          <a-tree-select
              style="width: 100%"
              v-model="form.channelId"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              placeholder="请选择放行的通道"
              tree-default-expand-all
              :tree-data="localChannelTree">
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="放行车牌" required>
          <a-row>
            <PlateNumberInput :plate="plate" v-model.trim="plate"  placeholder="请输入弹框的车牌" />
          </a-row>
          <a-row v-if="passSimilarPlates">
            <a-button
                v-for="item in passSimilarPlates"
                :key="item.plateNumber"
                @click="onPassPlateClick(item.plateNumber)">
              {{ item.plateNumber }}
              <span style="color: #8a8a8a">
               ({{ item.passTime }})
            </span>
            </a-button>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
</template>

<script>
import PlateNumberInput from "../commons/PlateNumberInput";
import {manualPass, similarPlateInParking} from "../../api/opration";
import {mapGetters} from "vuex";

export default {
  name: "ManualPass",
  components: { PlateNumberInput },
  props: {
    currentParkingId: {
      type: String,
      defaultValue: () => undefined
    },
    currentParkingPlate: {
      type: String,
      defaultValue: () => undefined
    },
    manualPassVisible: {
      type: Boolean,
      defaultValue: () => false
    },
    localChannelTree:  {
      type: Array,
      defaultValue: () => []
    }
  },
  data () {
    return {
      manualPassLoading: false,
      plate: this.currentParkingPlate,
      passSimilarPlates: [],
      form: {
        channelId: undefined,
      },
      rules: {
        channelId: { required: true, message: '放行通道必须选择'}
      },
      confirmLoading: false
    }
  },
  computed: {
    ...mapGetters(['currentAnswer'])
  },
  watch: {
    plate: function (newVal) {
      this.loadPlateSimilarPlate(newVal)
    },
    currentParkingPlate() {
      this.plate = this.currentParkingPlate
    }
  },
  methods: {
    manualPassOk () {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          return false;
        }
        const data = { ...this.form }
        data.parkingId = this.currentParkingId
        if (this.currentAnswer) {
          data.callId = this.currentAnswer.id
        }
        if (this.plate) {
          data.plate = this.plate
        } else {
          this.$message.error('放行车牌必须输入')
          return false
        }
        this.confirmLoading = true
        return manualPass(data).then(()=> {
          this.$message.success('手动放行"' + data.plate + '"操作成功')
          this.$emit('loadLocalChannelInfo')
          this.onCancel()
        }).catch((e) => {
          this.$message.error('手动放行失败 ' + e.message)
        }).finally(() => {
          this.confirmLoading = false
        })
      })
    },
    onPassPlateClick (value) {
      this.plate = value
    },
    onCancel () {
      this.$emit('cancelManualPass')
      this.$refs.ruleForm.resetFields()
      this.plate = this.currentParkingPlate
    },
    loadPlateSimilarPlate (plate) {
      similarPlateInParking(
          {
            parkingId: this.currentParkingId,
            plate: plate}
      ).then(res => {
        this.passSimilarPlates = res.plates
      }).catch(e => {
        console.error('获取相似车牌错误' + e.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
