<template>
 <div>
   <a-form-model layout="inline" :model="form" @submit="handleSubmit">
     <a-form-model-item label="车牌号码">
       <a-input size="small" v-model="form.plateNumber" style="width: 100px"/>
     </a-form-model-item>
     <a-form-model-item label="客户名称">
       <a-input size="small" v-model="form.clientName" style="width: 100px"/>
     </a-form-model-item>
     <a-form-model-item label="电话号码">
       <a-input size="small" v-model="form.phoneNumber" style="width: 100px"/>
     </a-form-model-item>
     <a-form-model-item>
       <a-radio-group :default-value="true" button-style="solid" v-model="form.isExpired" size="small">
         <a-radio-button :value="true">
           已过期的
         </a-radio-button>
         <a-radio-button :value="false">
           未过期的
         </a-radio-button>
       </a-radio-group>
     </a-form-model-item>
     <a-form-model-item>
       <a-button size="small" type="primary" :loading="loading" htmlType="submit">
         查询
       </a-button>
     </a-form-model-item>
   </a-form-model>
   <a-table
       :columns="columns"
       :row-key="(record, index) => index"
       :data-source="dataSource"
       :pagination="pagination"
       :loading="loading"
       @change="handleTabChange"
   >
     <template slot="startDate" slot-scope="text"> {{ moment(text).format('YYYY-MM-DD') }} </template>
     <template slot="stopDate" slot-scope="text">
       <span :style="{ color: moment(text).isBefore(moment()) ?  'red' : 'green' }">{{ moment(text).format('YYYY-MM-DD') }}</span>
     </template>
   </a-table>
 </div>
</template>

<script>
import moment from "moment";
import { queryRegisteredCar } from '../../../api/opration'

const columns = [
  {
    title: '车牌',
    dataIndex: 'registerPlate',
    key: 'registerPlate'
  },
  {
    title: '通行证类型',
    dataIndex: 'passportTypeName',
    key: 'passportTypeName'
  },
  {
    title: '客户姓名',
    dataIndex: 'clientName',
    key: 'clientName'
  },
  {
    title: '开卡时间',
    dataIndex: 'startDate',
    key: 'startDate',
    scopedSlots: { customRender: 'startDate' }
  },
  {
    title: '有效期',
    dataIndex: 'stopDate',
    key: 'stopDate',
    scopedSlots: { customRender: 'stopDate' }
  },
  {
    title: '剩余次数',
    dataIndex: 'numberTimes',
    key: 'numberTimes'
  },
  {
    title: '余额',
    dataIndex: 'balance',
    key: 'balance'
  }
];

export default {
  name: "RegisteredCar",
  props: {
    currentParkingId: {
      type: String,
      value: undefined
    }
  },
  data() {
    return {
      columns,
      loading: false,
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        size: 'small',
        total: 0,
        showTotal: (total) => '共 ' + total + ' 条'
      },
      form: {
        plateNumber: '',
        isExpired: false
      }
    }
  },
  methods : {
    moment,
    onClickQuickTime(target, value, unit) {
      if (target === 'start') {
        this.form.enterStartTime = moment().subtract(value, unit)
      } else {
        this.form.enterEndTime = moment().subtract(value, unit)
      }
    },
    handleSubmit () {
      this.loadData()
    },
    handleTabChange (page) {
      console.info(page)
    },
    loadData () {
      const params = { ...this.form }
      params.current = this.pagination.current
      params.size = this.pagination.pageSize
      params.parkingId = this.currentParkingId
      this.loading = true
      queryRegisteredCar(params).then(res => {
        this.pagination.total = res.total
        this.dataSource = res.records
      }).catch(e => {
        this.$message.error('查询失败：' + e.message)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
