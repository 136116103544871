<template>
  <div>
    <a-badge :count="offLineData.length">
      <a href="#" @click="onClickParkingStatus" style="color: white;padding: 10px 15px 10px 10px">车场状态</a>
    </a-badge>
    <a-modal
      title="车场状态"
      :width="900"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
    >
      <a-radio-group v-model="state" @change="onStateChange" button-style="solid">
        <a-radio-button :value="1">
          离线车场
        </a-radio-button>
        <a-radio-button :value="2">
          在线车场
        </a-radio-button>
      </a-radio-group>
      <a-table
          :columns="columns"
          :data-source="state === 1 ? offLineData : onLineData"
          @change="handleTabChange"
      >
        <div slot="status" slot-scope="text">
          <span :style="{ color: text.online ? '#0099FF' : 'red' }">
            {{ text.online ? '在线' : '离线' }}
          </span>
        </div>
        <span slot="address" slot-scope="text, record">{{ record.province + '-' + record.city }}</span>
        <a slot="opt" slot-scope="text, record" @click="handleExpand(record.parkingId)">在/离线记录</a>
      </a-table>
    </a-modal>

    <a-modal
        title="历史在/离线记录"
        :width="900"
        :visible="historyVisible"
        @cancel="handleHistoryCancel"
        :footer="null"
    >
      <a-table
          :columns="innerColumns"
          :data-source="historyData"
          :pagination="false"
      >
        <span slot="type" slot-scope="text">{{ text === 'ONLINE' ? '上线' : '下线' }}</span>
        <span slot="createdTime" slot-scope="text">{{ moment(text).format('YYYY-MM-DD HH:mm:ss') }}</span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>

import {localParkingServer} from "../../api/opration";
import { parkingOnlinePage } from "../../api/parkingOnline";
import moment from 'moment'
const columns = [
    {
      title: '车场ID',
      dataIndex: 'parkingId',
      key: 'parkingId'
    },
    {
      title: '车场名称',
      dataIndex: 'parkingName',
      key: 'parkingName'
    },
    {
      title: '状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '所在省市',
      dataIndex: 'address',
      scopedSlots: { customRender: 'address' },
    },
  {
    title: '操作',
    dataIndex: 'opt',
    scopedSlots: { customRender: 'opt' },
  }
]

const innerColumns = [
  {
    title: '车场ID',
    dataIndex: 'parkingId',
    key: 'parkingId'
  },
  {
    title: '车场名称',
    dataIndex: 'parkingName',
    key: 'parkingName'
  },
  {
    title: '上/下线',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    scopedSlots: { customRender: 'createdTime' },
  }
]
export default {
  name: "ParkingStatus",
  data () {
    return {
      state: 1,
      visible: false,
      historyVisible: false,
      columns,
      innerColumns,
      pagination: {
        current: 1,
        pageSize: 10,
        size: 'small',
        total: 0,
        showTotal: (total) => '共 ' + total + ' 条'
      },
      onLineData: [],
      offLineData: [],
      historyData: []
    }
  },
  methods: {
    moment,
    handleExpand (parkingId) {
      parkingOnlinePage({ parkingId }).then(res => {
        this.historyData = res
      })
      this.historyVisible = true
    },
    onClickParkingStatus () {
      localParkingServer().then((res) => {
        this.onLineData = res.filter(item => {
          return item.status.online
        })
        this.offLineData = res.filter(item => {
          return !item.status.online
        })
      })
      this.visible = true
    },
    onStateChange (val) {
      this.state = val.target.value
      if (val === 1) {
        this.pagination.total = this.offLineData.length
      } else {
        this.pagination.total = this.onLineData.length
      }
    },
    handleTabChange (page) {
      this.pagination.current = page.current
    },
    handleCancel () {
      this.visible = false
    },
    handleHistoryCancel () {
      this.historyVisible = false
    }
  }
}
</script>

<style scoped>

</style>
