import request from "../utis/request";

const callApi = {
    channelInfo : '/unattended/operation/channelInfo',
    confirmDialog: '/unattended/operation/confirmDialog',
    cancelDialog: '/unattended/operation/cancelDialog/',
    modifyPass: '/unattended/operation/modifyPass',
    addEnterInfo: '/unattended/operation/addEnterInfo',
    manualPass: '/unattended/operation/manualPass',
    similarPlateInParking: '/unattended/operation/similarPlateInParking',
    channelOffOrNo: '/unattended/operation/channelOffOrNo',
    initiativeCall: '/unattended/operation/initiative-call',
    queryEnterRecord: '/unattended/operation/query-enter-record',
    queryHistoryRecord: '/unattended/operation/query-history-record',
    queryRegisteredCar: '/unattended/operation/query-registered-car',
    queryParkingConfig: '/unattended/operation/query-parking-config',
    screenshotUpload: '/unattended/operation/screenshot-upload',
    sendQrCode: '/unattended/operation/send-qrcode',
    localParkingServer: '/project/project/parking-status/local-parking-server'
}

export function channelInfo(params){
    return request({
        url: callApi.channelInfo,
        method: 'POST',
        data: params
    })
}

export function confirmDialog(params){
    return request({
        url: callApi.confirmDialog,
        method: 'POST',
        data: params
    })
}

export function cancelDialog(prams){
    return request({
        url: callApi.cancelDialog,
        method: 'POST',
        data: prams
    })
}

export function modifyPass(params){
    return request({
        url: callApi.modifyPass,
        method: 'POST',
        data: params
    })
}

export function addEnterInfo(params){
    return request({
        url: callApi.addEnterInfo,
        method: 'POST',
        data: params
    })
}

export function manualPass(params){
    return request({
        url: callApi.manualPass,
        method: 'POST',
        data: params
    })
}

export function similarPlateInParking(params){
    return request({
        url: callApi.similarPlateInParking,
        method: 'POST',
        data: params
    })
}

export function channelOffOrNo(params){
    return request({
        url: callApi.channelOffOrNo,
        method: 'POST',
        data: params
    })
}

export function initiativeCall(params) {
    return request({
        url: callApi.initiativeCall,
        method: 'POST',
        data: params
    })
}

export function queryEnterRecord(params) {
    return request({
        url: callApi.queryEnterRecord,
        method: 'POST',
        data: params
    })
}

export function queryHistoryRecord(params) {
    return request({
        url: callApi.queryHistoryRecord,
        method: 'POST',
        data: params
    })
}

export function queryRegisteredCar(params) {
    return request({
        url: callApi.queryRegisteredCar,
        method: 'POST',
        data: params
    })
}

export function queryParkingConfig(params) {
    return request({
        url: callApi.queryParkingConfirg,
        method: 'POST',
        data: params
    })
}

export function uploadFile(data) {
    return request({
        url: callApi.screenshotUpload,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export function localParkingServer() {
    return request({
        url: callApi.localParkingServer,
		method: 'GET'
    })
}

export function sendQrCode(data) {
    return request({
        url: callApi.sendQrCode,
        method: 'POST',
        data
    })
}
