<template>
  <a-layout class="container">
    <Head @reCall="(info) => reCall(info)" />
    <a-layout-content class="center">
      <a-row style="height: 100%;">
        <a-col :span="7" class="left_col">
          <div style="height: 50%;">
            <TRTCCall
                ref="hmCallComponent"
                @play-ring="playRing"
                @stop-ring="stopRing"
                parking-info="parkingInfo"
                @handler-answer-dh-call="handlerAnswerCall"
            />
          </div>
          <div style="height: 50%">
            <DhCall ref="dhCallComponent"
                    @play-ring="playRing"
                    @stop-ring="stopRing"
                    @handler-answer-dh-call="handlerAnswerCall"
                    @close-dh-call="closeDhCall"
            />
          </div>
        </a-col>
        <a-col :span="17" style="background-color: white; height: 100%;display: flex;flex-direction: column;">
          <a-row style="height: 40px;border: 1px solid #f0f0f0;padding-left: 20px">
            <a-form-model layout="inline">
              <a-form-model-item label="当前车场">
                <a-tree-select
                    v-model="currentParkingId"
                    @select="onParkingChange"
                    :disabled="isAnswering"
                    :treeData="parkings"
                    treeNodeFilterProp='title'
                    :show-search="true"
                    style="width: 300px"
                    :dropdown-style="{ maxHeight: '500px',height: '500px', overflow: 'auto' }"
                    placeholder="请选择要操作的停车场"
                    allow-clear
                    tree-default-expand-all
                >
                </a-tree-select>
              </a-form-model-item>
              <a-form-model-item label="处理通道" v-if="channelTreeData && channelTreeData.length > 0">
                <a-tree-select
                  v-model="currentChannelId"
                  style="width: 200px"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto', fontSize: '16px' }"
                  :disabled="currentParkingId && false"
                  :tree-default-expand-all="true"
                  :tree-data="channelTreeData"
                  @change="onCurrentChannelChange"
                >
                </a-tree-select>
              </a-form-model-item>
              <a-form-model-item v-if="currentParkingId && currentChannelId">
                <a-button @click="getLocalChannelInfo()">获取通道信息</a-button>
              </a-form-model-item>
              <a-form-model-item v-if="!isAnswering && channelAttr && channelAttr.dihuDeviceNo">
                <a-button @click="activeCall(channelAttr.dihuDeviceNo)">DH对讲</a-button>
              </a-form-model-item>
              <a-form-model-item v-if="!isAnswering && channelAttr && channelAttr.lcdDeviceNo">
                <a-button @click="activeHmCall(channelAttr.lcdDeviceNo)">红门对讲</a-button>
              </a-form-model-item>
              <a-form-model-item v-if="channelInfo">
                <a-button type="danger" @click="onFinishHandle">结束处理</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-row>
          <a-row v-if="channelErrorMessage" style="background-color: white;padding:20px 5px;height: 100%;text-align: center;color: red">
            {{ channelErrorMessage }}
          </a-row>
          <div v-if="channelInfo" style="background-color: white; padding: 0 5px; margin-top: 10px; flex: 1; overflow-y: scroll" :style="{ backgroundColor: 'white', padding: '0 5px', marginTop: '10px',flex: 1, scrollY: 'auto' }">
            <a-row  style="height: 300px">
              <a-col  :span="12">
                <Picture :channel-picture="channelPicture" />
              </a-col>
              <a-col :span="12" style="padding: 0 2px;height: 100%">
                <div v-if="monitor && !monitor.success" style="height: 100%;color: red;display: flex; justify-content: center;align-items: center">
                  {{ monitor.message }}
                </div>
                <template v-if="monitor && monitor.monitorAddress">
                  <iframe  width="100%" height="100%" :src="monitor.monitorAddress"></iframe>
                </template>
             </a-col>
            </a-row>
            <ChannelInfo :channel-info="channelInfo"/>
            <Passport v-if="showPassport && channelInfo.passport" :passport="channelInfo.passport"/>
            <a-row type="flex" justify="start">
              <a-col :span="2">
                <a-button :disabled="manualDisable" size="small" @click="getLocalChannelInfo">刷新</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="manualDisable" size="small" @click="onManual">手动放行</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="confirmDialogDisable" size="small" @click="confirmVisible = true">确认弹框</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="confirmDialogDisable" size="small" @click="cancelFrame">取消弹框</a-button>
              </a-col>
              <a-col :span="3">
                <a-button :disabled="modifyPassDisable" size="small" @click="modifyPassVisible = true">修改过车数据</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="!(currentParkingId && currentChannelId)" size="small" @click="addEnterVisible = true">补录入场</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="!(currentParkingId && currentChannelId)" size="small" type="primary" @click="channelCloseGate(1)">起杆</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="!(currentParkingId && currentChannelId)" size="small" type="danger"  @click="channelCloseGate(2)">落杆</a-button>
              </a-col>
              <a-col :span="2">
                <a-button :disabled="!(currentParkingId && currentChannelId)" size="small" @click="onSendQrCode">发送二维码</a-button>
              </a-col>
            </a-row>
            <ParkingInfo
                :pass-info="channelInfo.passInfo"
                :currentParkingId="currentParkingId"
            />
          </div>
          <DeviceStatusPanel v-else/>
        </a-col>
      </a-row>

      <audio controls ref="notify" :src="ringPath" style="display: none" />

      <ManualPass
          ref="manualPass"
          v-if="channelInfo"
          :manual-pass-visible="manualPassVisible"
          :current-parking-id="currentParkingId"
          :current-channel-id="currentChannelId"
          :local-channel-tree="localChannelTree"
          :current-parking-plate="currentParkingPlate"
          @loadLocalChannelInfo="getLocalChannelInfo"
          @cancelManualPass="manualPassVisible = false"
      />

      <ConfirmWindow
          v-if="channelInfo"
          :current-parking-id="currentParkingId"
          :visible="confirmVisible"
          :channel-info="channelInfo"
          @cancel="confirmVisible = false"
          @loadLocalChannelInfo="getLocalChannelInfo"
      />

      <ModifyPass
          v-if="channelInfo"
          :visible="modifyPassVisible"
          :current-parking-id="currentParkingId"
          :channel-info="channelInfo"
          @cancel="modifyPassVisible = false"
          @loadLocalChannelInfo="getLocalChannelInfo"
      />

      <AddEnter
          v-if="channelInfo"
          :visible="addEnterVisible"
          :current-parking-id="currentParkingId"
          :local-channel-tree="localChannelTree"
          :channel-info="channelInfo"
          @loadLocalChannelInfo="getLocalChannelInfo"
          @cancel="addEnterVisible = false"
      />
      <a-modal
          :title="'通道' + (poleForm.gateSwitch === 1 ? '开闸' : '关闸')"
          :visible="poleVisible"
          okText="确定"
          cancelText="取消"
          @ok="onPoleOperationOk"
          @cancel="poleVisible = false"
      >
          <a-form-model
            ref="poleRoleForm"
            :model="poleForm"
            :label-col="{ span: 4}"
            :wrapper-col="{ span: 20 }">
            <a-form-model-item label="放行方式">
              <a-radio-group v-model="poleForm.releaseType">
                <a-radio-button :value="0">
                  非法起/落杆
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <ReasonChoose v-model="poleForm.remark" />
            <a-form-model-item label="输入备注">
              <a-input v-model="poleForm.remark" placeholder="输入或选择备注"/>
            </a-form-model-item>
        </a-form-model>
      </a-modal>

<!--      <LineAlarm/>-->
    </a-layout-content>
  </a-layout>
</template>
<script>
import {channelInfo, cancelDialog, channelOffOrNo, sendQrCode} from "../api/opration"
import { getChannelTree, getMonitorAddress, getChannelAttr} from "../api/parking"
import ringPath from '@/assets/huahai.mp3'
import Head from '../components/Head/Head'
import DhCall from "../components/Call/DH/DhCall"
import Picture from "../components/Info/Picture";
import ChannelInfo from "../components/Info/ChannelInfo"
import { mapGetters, mapActions } from 'vuex'
import Passport from "../components/Info/Passport";
import ParkingInfo from "../components/Info/parking/ParkingInfo";
import TRTCCall from "../components/Call/TRTC/TRTCCall";
import ManualPass from "../components/Operationnal/ManualPass";
import ConfirmWindow from "../components/Operationnal/ConfirmWindow";
import ModifyPass from "../components/Operationnal/ModifyPass";
import AddEnter from "../components/Operationnal/AddEnter";
// import LineAlarm from "../components/Operationnal/LineAlarm";
import pinyin from 'js-pinyin'
import DeviceStatusPanel from "../components/Call/Device/DeviceStatusPanel";
import ReasonChoose from "../components/Operationnal/ReasonChoose";
import store from '@/store'

export default {
  name: 'Home',
  components: {
    ReasonChoose,
    DeviceStatusPanel,
    AddEnter,
    ModifyPass,
    ConfirmWindow,
    ManualPass,
    TRTCCall,
    ParkingInfo,
    Passport,
    ChannelInfo,
    Picture,
    Head,
    DhCall
  },
  data: function() {
    return {
      test: '',
      ringPath,
      currentParkingId: undefined,
      currentChannelId: null,
      currentParkingPlate: undefined,
      channelAttr: {},
      requestId: null,
      channelErrorMessage: null,
      parkings: [],
      channelTreeData: [],
      expandChannel: false,

      manualPassVisible: false,
      confirmVisible: false,
      modifyPassVisible: false,
      addEnterVisible: false,
      poleVisible: false,
      poleLoading: false,
      poleForm: {
        gateSwitch: 2,
        releaseType: 0,
        remark: undefined
      },

      showPassport: false,
      messageList: [],
      height: 0,
      localChannelTree: [],
      channelInfo: null,
      channelPicture: null,

      monitor: {
        success: false,
        message: '正在获取播放地址,请稍后...',
        monitorAddress: undefined
      }
    }
  },
  mounted () {
    this.parkings = this.dutyParking.map((p) => {
      return {
        title: p.name + '(' + pinyin.getCamelChars(p.name) + ')',
        value: p.parkingId
      }
    })
  },
  watch: {
    isRing (val) {
      if (val) {
        this.playRing()
      } else {
        this.stopRing()
      }
    }
  },
  computed : {
    ...mapGetters(['isDhAnswering', 'isHmAnswering', 'isAnswering', 'currentAnswer', 'userInfo', 'getReasonsStr', 'isRing', 'dutyParking', 'currentCallLock']),
    manualDisable: function () {
      return !this.currentParkingId
    },
    confirmDialogDisable: function () {
      if (!this.currentParkingId || !this.currentChannelId) {
       return  true
      } else if (this.channelInfo.state === 'WAIT_CONFIRM') {
        return false;
      } else {
        return true
      }
    },
    modifyPassDisable: function () {
      if (!this.currentParkingId || !this.currentChannelId) {
        return true
      } else if (this.channelInfo.state === 'PASS_REJECTED' || this.channelInfo.state === 'WAIT_CONFIRM') {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['callExecute']),
    onSendQrCode () {
      const data = { parkingId: this.currentParkingId, channelId: this.currentChannelId, requestId: this.requestId }
      if(this.currentAnswer) {
        data.callId = this.currentAnswer.id
      }
      sendQrCode(data).then(() => {
        this.$message.success('发送成功')
      }).catch(e => {
        this.$message.error('发送失败：' + e.message)
      })
    },
    //取消通道下弹框
    cancelFrame () {
      this.$confirm({
        title: '确定取消通道下的弹框吗?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          return new Promise((resolve, reject)  => {
            const  params = { parkingId: this.currentParkingId, channelId: this.currentChannelId, requestId: this.requestId }
            if(this.currentAnswer) {
              params.callId = this.currentAnswer.id
            }
            cancelDialog(params).then(() => {
              resolve()
            }).catch(e => {
              reject(e)
            })
          }).then(() => {
            this.getLocalChannelInfo()
            this.$message.success('取消弹框成功')
          }).catch((e) => {
            this.$message.error('取消弹框失败：' + e.message)
          })
        }
      });
    },
    //通道开/关闸
    channelCloseGate (target) {
      this.poleVisible = true;
      this.poleForm.gateSwitch = target
    },
    reCall(info){
      const { parkingId, channelId, deviceNo } = info
      this.currentParkingId = parkingId
      this.currentChannelId = channelId
      if (parkingId) {
        getChannelTree(parkingId).then(res => {
          this.channelTreeData = res
          this.expandChannel = true
        });
        this.channelErrorMessage = undefined
        this.channelAttr = null
        this.activeHmCall(deviceNo)
      }
    },
    onPoleOperationOk () {
      this.poleForm.parkingId = this.currentParkingId
      this.poleForm.channelId = this.currentChannelId
      if (this.currentAnswer) {
        this.poleForm.callId = this.currentAnswer.id
      }
      this.poleLoading = true
      channelOffOrNo(this.poleForm).then(() => {
        this.poleVisible = false
        this.poleForm.releaseType = 0
        this.$message.success((this.poleForm.gateSwitch === 1 ? '开闸' : '关闸') + '通道成功')
      }).catch((e) => {
        this.$message.success((this.poleForm.gateSwitch === 1 ? '开闸' : '关闸') + '通道失败' + e.message)
      }).finally(() => {
        this.poleLoading = false
      })
    },
    onManual () {
      this.currentParkingPlate = this.channelInfo.plate
      this.manualPassVisible = true
      this.$refs.manualPass.form.channelId = this.currentChannelId
    },
    handlerAnswerCall() {
      if(this.currentAnswer && this.currentAnswer.parkingId) {
        this.onParkingChange(this.currentAnswer.parkingId)
        if(this.currentAnswer.channelId) {
          this.currentChannelId =  this.currentAnswer.channelId
          this.onCurrentChannelChange(this.currentChannelId)
          this.getLocalChannelInfo()
        }
      }
    },
    closeDhCall() {
      this.onFinishHandle()
    },
    buildLocalChannelTree () {
      let tree = []
      for (const item of this.channelInfo.structureSpace) {
        let node = {title: item.areaName, value: 'a' + item.areaId, key: 'a' + item.areaId, selectable: false, disabled: true}
        if (item.subAreas || item.enterChannels || item.exitChannels){
          node.children = []
          node.children.push( ...this.getChannels(item))
          node.children.push( ...this.getAreas(item.subAreas))
        }
        tree.push(node)
      }
      this.localChannelTree = tree
    },
    getAreas (areas) {
      let nodes = []
      if(areas) {
        for (const item of areas) {
          let node = {title: item.areaName, value: 'a' + item.areaId, key:  'a' +item.areaId, selectable: false, disabled: true}
          if(item.subAreas || item.enterChannels || item.exitChannels){
            node.children = []
            node.children.push( ...this.getChannels(item) )
            node.children.push( ...this.getAreas(item.subAreas))
          }
          nodes.push(node)
        }
      }
      return nodes
    },
    getChannels (item) {
      let nodes = []
      if (item.enterChannels) {
        for (const enterItem of item.enterChannels) {
          nodes.push({title: enterItem.channelName + '(入口)', value: enterItem.channelId, key: 'c' + enterItem.channelId, selectable: true});
        }
      }
      if (item.exitChannels) {
        for (const exitItem of item.exitChannels) {
          nodes.push({title: exitItem.channelName + '(出口)', value: exitItem.channelId, key: 'c' + exitItem.channelId, selectable: true})
        }
      }
      return nodes
    },
    loadParkings () {
      // getUserParkingTree().then(res => {
      //   res.map(item => {
      //     (item.children) && (
      //         item.children.map(p => {
      //           p.title = p.title + '(' + pinyin.getCamelChars(p.title) + ')'
      //         })
      //     )
      //   })
      //   this.parkings = res
      // })
    },
    onParkingChange (value) {
      this.currentParkingId = value
      if (this.currentParkingId) {
        getChannelTree(value).then(res => {
          this.channelTreeData = res
          this.expandChannel = true
        });
      }
      this.channelErrorMessage = undefined
      this.currentChannelId = null
      this.channelAttr = null
    },
    onCurrentChannelChange (val) {
      getChannelAttr(this.currentParkingId, val).then(res => {
        this.channelAttr = res
        this.playMonitor()
      }).catch(e => {
        this.$message.error('获通道属性失败：' + e.message)
      })
    },
    getLocalChannelInfo () {
      const params = { 'parkingId': this.currentParkingId, 'channelId': this.currentChannelId }
      if (this.currentAnswer) {
        params.callId = this.currentAnswer.id
      }
      this.channelErrorMessage = null
      channelInfo(params).then(res => {
        this.$message.info('获取通道下信息成功');
        this.channelInfo = res
        this.requestId = res.passRequestId
        setTimeout(() => {
          this.channelPicture = this.channelInfo && this.channelInfo.bodyPicUrl
        }, 2000)
        this.buildLocalChannelTree()
      }).catch(e => {
        this.$message.error('获取通道下信息异常:' + e.message)
        this.channelErrorMessage = e.message
      })
    },
    onFinishHandle () {
      this.channelInfo = null
      this.channelPicture = null
      this.monitor = {
        success: true,
        message: '获取监控地址中，请稍后...'
      }
      if (this.answer != null) {
        this.answer = null
      }
      if (this.isDhAnswering) {
        // this.$refs.dhCallComponent.handlerHanUpDhCall(this.currentAnswer.deviceNo)
      }
      if(this.isHmAnswering) {
        this.$refs.hmCallComponent.hangUp()
      }
    },
    activeHmCall (deviceNo) {
      this.callExecute(deviceNo).then((result) => {
        if(result){
          this.$refs.hmCallComponent.call(deviceNo)
          setTimeout(()  => {
            this.getLocalChannelInfo()
          }, 500)
          this.playMonitor()
        }
      })
      
    },
    activeCall (deviceNo) {
      this.$refs.dhCallComponent.handlerActiveCall(deviceNo)
      setTimeout(()  => {
        this.getLocalChannelInfo()
      }, 500)
      this.playMonitor()
    },
    playRing () {
      if (!this.isAnswering) {
        this.$refs.notify.currentTime = 0
        this.$refs.notify.play()
      }
    },
    stopRing () {
      this.$refs.notify.pause()
    },
    playMonitor() {
      this.monitor = undefined
      const monitorDeviceNo = this.channelAttr && this.channelAttr.monitorDeviceNo
      const monitorPwd = this.channelAttr && this.channelAttr.monitorPwd
      if (monitorDeviceNo || monitorPwd) {
        getMonitorAddress(monitorDeviceNo, monitorPwd).then(res => {
          this.monitor = {
            success: true,
            message: 'success',
            monitorAddress: res && res.monitorAddress
          }
        }).catch(e => {
          this.monitor = {
            success: true,
            message: e.message
          }
        })
      }
    },
    renewToken() {
      try{
        setInterval(() => {
          store.dispatch('GetInfo')
        },1000*60*60*1.5)
      }catch(err) {
        console.log(err)
      }
    }
  },
  created() {
    this.loadParkings()
    Notification.requestPermission()
    this.renewToken() // 2小时内请求一次防止token过期
  }
}
</script>
<style>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center {
  flex-grow: 1;
}

.left_col {
  border-right: 1px solid #cbcaca;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.ant-tabs-nav .ant-tabs-tab{
  font-size: 12px;
  padding: 5px;
}

fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
</style>
