<template>
  <div>
    <a-badge :count="pagination.total">
      <a href="#" @click="onShowRedress" style="color: white;padding: 10px 15px 10px 10px">车牌矫正</a>
    </a-badge>
    <a-drawer
        title="车牌矫正"
        placement="right"
        :closable="true"
        :visible="visible"
        @close="onClose"
        :pagination="pagination"
        :width="800"
        @change="handleTabChange"
    >
      <a-form-model ref="queryForm" layout="inline" :model="params" >
        <a-form-model-item label="停车场">
          <a-tree-select
              v-model="params.parkingId"
              :treeData="parkingList"
              treeNodeFilterProp='title'
              :show-search="true"
              style="width: 300px"
              :dropdown-style="{ maxHeight: '500px',height: '500px', overflow: 'auto' }"
              placeholder="请选择车场"
              allow-clear
              tree-default-expand-all />
        </a-form-model-item>
        <a-form-model-item label="车牌号码">
          <a-input v-model="params.plateNumber" placeholder="请输入车牌号码" />
        </a-form-model-item>
        <a-form-model-item label="入场时间">
          <a-date-picker @change="onDateChange" placeholder="请选择日期" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="loadRedressPlate">查询</a-button>
          <a-button type="default" style="margin-left: 10px" @click="onResetQueryForm">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="columns" :data-source="data" key="id">
        <span slot="parkingId" slot-scope="text">
        {{ getParkingName(text) }}
       </span>
        <span slot="trust" slot-scope="text">
        {{ text }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a @click="onRedress(index)">人工比对</a>
        </span>
      </a-table>
    </a-drawer>
    <a-modal
        title="矫正车牌"
        :width="700"
        :visible="redressVisible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <img :src="form.enterRegPlatePicture || wtp" style="height: 500px; width: 100%; margin-bottom: 10px"/>
        <a-descriptions bordered :column="2">
          <a-descriptions-item label="识别到的车牌：" :span="2">
            {{ form.enterRegPlate }}
          </a-descriptions-item>
          <a-descriptions-item label="矫正后的车牌：" :span="2">
            <a-input v-model="form.redressPlate" />
          </a-descriptions-item>
        </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import {getRedressPlateList, redressPlate} from "../../api/redressPlate";
import wtp from '../../assets/wtp.png'
import moment from 'moment'
import { getUserParkingTree } from "../../api/parking";

const columns = [
  {
    title: '停车场名称',
    dataIndex: 'parkingId',
    key: 'parkingId',
    scopedSlots: { customRender: 'parkingId' }
  },
  {
    title: '识别车牌',
    dataIndex: 'enterRegPlate',
    key: 'enterRegPlate'
  },
  {
    title: '入场通道',
    dataIndex: 'channelName',
    key: 'channelName'
  },
  {
    title: '入场时间',
    dataIndex: 'enterTime',
    key: 'enterTime'
  },
  {
    title: '置信度',
    key: 'trust',
    dataIndex: 'trust',
    scopedSlots: { customRender: 'trust' }
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  name: "RedressPlate",
  data () {
    return {
      moment,
      wtp,
      columns,
      visible: false,
      redressVisible: false,
      params: {
        current: 1,
        pageSize : 10
      },
      data: [],
      confirmLoading: false,
      imagePath: undefined,
      form: {},
      parkingList: [],
      parkingArray: [],
      pagination: {
        current: 1,
        pageSize: 10,
        size: 'small',
        total: 0,
        showTotal: (total) => '共 ' + total + ' 条'
      }
    }
  },
  mounted() {
   this.loadRedressPlate()
    this.loadParkingList()
  },
  methods: {
    onClose () {
      this.visible = false
    },
    onRedress (index) {
      const row = this.data[index]
      this.form = {
        ...row
      }
      this.form.redressPlate = row.enterRegPlate
      this.redressVisible = true
    },
    handleOk () {
      if (!this.form.redressPlate) {
        this.$message.error('请输入车牌')
        return
      }
      this.confirmLoading = true
      redressPlate(this.form).then(() => {
        this.$message.success('矫正成功')
        this.redressVisible = false
        this.loadRedressPlate()
      }).catch(e => {
        this.$message.error('矫正失败:' + e.message)
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    handleCancel () {
      this.form = {}
      this.redressVisible = false
      this.confirmLoading = false
    },
    onShowRedress () {
      this.visible = true
    },
    handleTabChange (page) {
      this.pagination.current = page.current
      this.loadRedressPlate()
    },
    loadParkingList () {
      getUserParkingTree().then(res => {
        this.parkingList = res
        res.forEach(item => {
          this.parkingArray = this.parkingArray.concat(item.children)
        })

      })
    },
    getParkingName (text) {
      let parkingName = ''
      for (const item of this.parkingArray) {
        if (text === item.key) {
          parkingName = item.title
        }
      }
      return parkingName
    },
    onDateChange (date, dateStr) {
      this.params.enterTime = dateStr
    },
    loadRedressPlate () {
      this.params.current = this.pagination.current
      this.params.pageSize = this.pagination.pageSize
      getRedressPlateList(this.params).then(res => {
        this.data = res.records
        this.pagination.total = res.total
      }).catch((e) => {
        this.$message.error('加载待矫正车牌失败')
        console.info(e)
      });
    },
    onResetQueryForm () {
      this.params = {}
    }
  }
}
</script>

<style scoped>

</style>