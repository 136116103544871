<template>
  <a-row class="row_class" :style="{ backgroundColor: onLine ? 'white' : 'red', color: onLine ? 'black' : 'white'}" type="flex" justify="space-around" align="middle">
    <a-col :span="4">DH呼叫</a-col>
    <a-col :span="12" :style="{ textAlign: 'center', color: stateColor}">{{ message }}</a-col>
    <a-col :span="4">
      <a-icon v-if="onLine" type="customer-service" theme="twoTone" two-tone-color="#52c41a" />
      <a-icon v-if="!onLine" type="coffee" />
      <span>{{ onLine ? '在线' : '离线' }}</span>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "DhState",
  props: {
    onLine: {
      type: Boolean,
      defaultValue: false,
    },
    state: {
      type: String,
      defaultValue: 'info'
    },
    message: {
      type: String,
      defaultValue: undefined
    }
  },
  computed: {
    stateColor: function () {
      switch (this.state) {
        case 'info':
          return '#CCCCCC'
        case 'warn':
          return ''
        case 'error':
          return '#CC0000'
        case 'success':
          return '#00CC33'
        default:
          return 'black'
      }
    }
  }
}
</script>

<style scoped>
  .row_class {
    background-color: white;
    height: 30px;
  }
</style>
