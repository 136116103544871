<template>
  <div class="container">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="停车场信息">
        <div style="margin-left: 10px">
          <a-descriptions v-if="parking" title="基本信息">
            <a-descriptions-item label="停车场编号">
              {{ parking.parkingId }}
            </a-descriptions-item>
            <a-descriptions-item label="联系人">
              {{ parking.contactPerson }}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{ parking.contactPhone }}
            </a-descriptions-item>
            <a-descriptions-item label="所属组织">
              {{ parking.organizationName }}
            </a-descriptions-item>
            <a-descriptions-item label="地址" :span="2">
              {{ parking.province }}  {{ parking.city }} {{ parking.area }} {{ parking.address }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div style="margin-left: 10px">
          <a-descriptions>
            <a-descriptions-item label="远程管理">
              <template v-if="frpDomain">
                <a :href="'http://' + frpDomain + '.r.hmzn.cn'" target="_blank">点击默认浏览器打开</a>
                <span style="margin-left: 10px">
                  <a-tooltip placement="top">
                    <template #title>点击复制</template>
                    <a-button type="link" icon="copy"  v-clipboard:copy="'http://' + frpDomain + '.r.hmzn.cn'" v-clipboard:success="onSuccess"/>
                  </a-tooltip>
                </span>
              </template>
              <template v-else>
                未配置
              </template>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div v-for="group in configures" :key="group.groupName" style="margin-top: 10px;margin-left: 10px">
          <a-descriptions :column="4" :title="group.groupName">
            <a-descriptions-item v-for="item in group.children" :key="item.id" :label="item.configureItemName">
              <a-tooltip>
                <template #title>复制成功</template>
                {{ item.configureItemValue }}<a-button type="link" icon="copy"  v-clipboard:copy="item.configureItemValue" v-clipboard:success="onSuccess"/>
              </a-tooltip>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="场内车辆">
        <EnterRecord :current-parking-id="currentParkingId"/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="历史出入">
        <HistoryRecord :current-parking-id="currentParkingId"/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="注册车">
        <RegisteredCar :current-parking-id="currentParkingId"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>

import EnterRecord from "../local/EnterRecord";
import HistoryRecord from "../local/HistoryRecord";
import RegisteredCar from "../local/RegisteredCar";
import { getParking } from "../../../api/parking";
import { getConfigureList, getFrpDomain } from '../../../api/parkingConfigure'

export default {
  name: "ParkingInfo",
  components: {RegisteredCar, HistoryRecord, EnterRecord},
  props: {
    currentParkingId: {
      type: String,
      defaultValue: () => undefined
    }
  },
  data() {
    return {
      parking: undefined,
      configures: [],
      frpDomain: undefined,
      type: 1
    }
  },
  watch: {
    currentParkingId: function () {
      this.loadParking()
      this.loadUnattendedConfigure()
      this.loadFrpDomain()
    }
  },
  mounted() {
    this.loadParking()
    this.loadUnattendedConfigure()
    this.loadFrpDomain()
  },
  methods: {
    loadParking() {
      getParking(this.currentParkingId).then(res => {
        this.parking = res
      })
    },
    loadUnattendedConfigure () {
      getConfigureList({ type: this.type, parkingId: this.currentParkingId }).then(res => {
        this.configures = this.build(res)
      })
    },
    build (list) {
      if (!list || list.length === 0) {
        return false
      }
      const groups = []
      for (const item of list) {
        if (groups.indexOf(item.groupName) === -1) {
          groups.push(item.groupName)
        }
      }
      const results = []
      for (const group of groups) {
        const children = list.filter(item => {
          return item.groupName === group
        })
        results.push({ groupName: group, children })
      }
      return results
    },
    onSuccess () {
      this.$message.success('复制成功')
    },
    loadFrpDomain () {
      if (this.currentParkingId) {
        getFrpDomain(this.currentParkingId).then(res => {
          if(res.subdomain) {
            this.frpDomain = res.subdomain
          }
        })
      }
    }
  }
}
</script>

<style scoped>
  .container {
    margin-top: 5px;
    font-size: 16px;
    height: 1100px;
  }
</style>
