<template>
  <a-modal
      title="修改过车参数"
      :maskClosable="false"
      :visible="visible"
      :confirm-loading="loading"
      okText="确定"
      cancelText="取消"
      @ok="onOk"
      @cancel="onCancel"
  >
    <a-row style="margin-bottom: 5px;font-size: 15px" v-if="channelInfo">
      <a-col :offset="2" :span="8" style="text-align: right" v-if="channelInfo.parkingInfo">停车场: {{ channelInfo.parkingInfo.parkingName }}</a-col>
      <a-col :offset="2" :span="8">通道: {{ channelInfo.channelName }}</a-col>
    </a-row>
    <a-form-model
        layout="horizontal"
        ref="modifyForm"
        :model="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item label="通行车牌" ref="plate" prop="plate">
        <a-row>
          <PlateNumberInput :plate="plate" v-model.trim="plate"  placeholder="请输入弹框的车牌" />
        </a-row>
        <a-row v-if="passSimilarPlates">
          <a-button
              v-for="item in passSimilarPlates"
              :key="item.plateNumber"
              @click="onPassPlateClick(item.plateNumber)">
            {{ item.plateNumber }}
            <span style="color: #8a8a8a">
               ({{ item.passTime }})
            </span>
          </a-button>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="车类型">
        <a-radio-group v-if="channelInfo && channelInfo.localAttr"  v-model="form.carTypeId">
          <a-radio-button v-for="item in channelInfo.localAttr.carTypes" :key="item.carTypeId" :value="item.carTypeId">
            {{ item.carTypeName }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="修改入场车牌">
        <a-switch checked-children="是" un-checked-children="否" v-model="modifyEnterPlate" @change="onModifyEnterPlateChange"/>
      </a-form-model-item>
      <a-form-model-item v-if="modifyEnterPlate" label="入场车牌">
        <a-row>
         <PlateNumberInput :plate="enterPlate" v-model.trim="enterPlate" placeholder="输入弹框入场的车牌" />
        </a-row>
        <div v-if="enterSimilarPlates">
          <a-button
              v-for="item in enterSimilarPlates"
              :key="item.plateNumber"
              @click="onEnterPlateClick(item.plateNumber)">
            {{ item.plateNumber }}
            <span style="color: #8a8a8a">
               ({{ item.passTime }})
            </span>
          </a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="修改通行时间">
        <a-switch checked-children="是" un-checked-children="否" v-model="modifyPassTime" @change="onModifyPassTimeChange"/>
      </a-form-model-item>
      <a-form-model-item v-if="modifyPassTime" label="通行时间">
        <a-date-picker
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
            v-model="form.passTime"
        >
          <template slot="renderExtraFooter">
            <a-button size="small" @click="onClickQuickTime(1, 'h')">1小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(2, 'h')">2小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(5, 'h')">5小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(12, 'h')">12小时前</a-button>
            <a-button size="small" @click="onClickQuickTime(1, 'd')">1天前</a-button>
            <a-button size="small" @click="onClickQuickTime(2, 'd')">2天前</a-button>
          </template>
        </a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="修改停车费用">
        <a-switch checked-children="是" un-checked-children="否" v-model="modifyMoney" @change="onModifyMoneyChange"/>
      </a-form-model-item>
      <a-form-model-item v-if="modifyMoney" label="本次停车费">
        <a-row>
            <a-input-number :min="0" :step="0.1" :precision="2" v-model="form.finalCharge"/>
        </a-row>
      </a-form-model-item>
      <ReasonChoose v-model="form.remark" />
      <a-form-model-item label="输入备注">
        <a-input v-model="form.remark" placeholder="输入或选择备注"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import PlateNumberInput from "../commons/PlateNumberInput";
import {mapGetters} from "vuex";
import moment from "moment";
import {modifyPass, similarPlateInParking} from "../../api/opration";
import ReasonChoose from "./ReasonChoose";

export default {
  name: "ModifyPass",
  components: {ReasonChoose, PlateNumberInput},
  props: {
    visible: {
      type: Boolean,
      defaultValue: () => false,
      required: true
    },
    currentParkingId: {
      type: String,
      defaultValue: () => undefined,
    },
    channelInfo: {
      type: Object,
      defaultValue: () => undefined
    }
  },
  computed: {
    ...mapGetters(['currentAnswer'])
  },
  watch: {
    plate: function (newVal) {
      this.loadPlateSimilarPlate(newVal)
    },
    enterPlate: function (newVal) {
      this.loadEnterSimilarPlate(newVal)
    }
  },
  data () {
    return {
      loading: false,
      passSimilarPlates: [],
      enterSimilarPlates: [],
      plate: this.channelInfo.plate,
      enterPlate: this.channelInfo.enterPlate,
      form: {
        carTypeId: this.channelInfo.carTypeId,
        passTime: undefined,
        finalCharge: undefined
      },
      modifyEnterPlate: false,
      modifyMoney: false,
      modifyPassTime: false
    }
  },
  methods: {
    moment,
    onOk () {
      const data = { ...this.form }
      if (!this.plate) {
        this.$message.error('通行车牌必须输入')
        return false;
      } else {
        data.plate = this.plate
      }
      data.enterPlate = this.enterPlate
      if (this.currentAnswer) {
        data.callId = this.currentAnswer.id
      }
      data.parkingId = this.currentParkingId
      data.channelId = this.channelInfo.channelId
      if (data.passTime) {
        data.passTime = data.passTime.format('yyyy-MM-DD HH:mm:ss');
      }
      this.loading = true;
      modifyPass(data).then(() => {
        this.$message.success('修改放行参数成功')
        this.$emit('loadLocalChannelInfo')
        this.onCancel()
      }).catch(e =>
          this.$message.error('修改放行参数失败' + e.message)
      ).finally(() =>
          this.loading = false
      )
    },
    onCancel () {
      this.$emit('cancel')
      this.$refs.modifyForm.resetFields()
    },
    onEnterPlateClick (value) {
      this.enterPlate = value
    },
    onPassPlateClick(value) {
      this.plate = value
    },
    onClickQuickTime( value, unit) {
      this.form.passTime = moment().subtract(value, unit)
    },
    onModifyEnterPlateChange (e) {
      if (e) {
        if (this.channelInfo) {
          this.enterPlate = this.channelInfo.plate
        }
        this.loadEnterSimilarPlate(this.channelInfo.plate)
      } else {
        this.enterPlate = undefined
      }
    },
    onModifyPassTimeChange (e) {
      if (e) {
        this.form.passTime = this.channelInfo.passTime
      } else {
        this.form.passTime = undefined
      }
    },
    onModifyMoneyChange (e) {
      if (e) {
        if (this.channelInfo && this.channelInfo.fee) {
          this.form.finalCharge = this.channelInfo.fee.shouldCharge;
        }
      } else {
       this.form.finalCharge = undefined
      }
    },
    loadPlateSimilarPlate (plate) {
      similarPlateInParking(
          {
            parkingId: this.currentParkingId,
            plate: plate}
      ).then(res => {
        this.passSimilarPlates = res.plates
      }).catch(e => {
        console.error('获取相似车牌错误' + e.message)
      })
    },
    loadEnterSimilarPlate (plate) {
      similarPlateInParking(
          {
            parkingId: this.currentParkingId,
            plate: plate
          }).then(res => {
        this.enterSimilarPlates = res.plates
      }).catch(e => {
        console.error('获取相似车牌错误' + e.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
