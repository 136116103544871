<template>
  <div>
    <a-row v-if="channelInfo">
      <a-col :span="10"  v-if="channelInfo.state === 'NO_VEHICHLE_EXIST'" style="padding: 20px 50px">
        <h2 style="color:#e36651">通道下未识别车辆</h2>
      </a-col>
      <a-col :span="10" v-else>
        <a-divider orientation="left">通行信息</a-divider>
        <a-descriptions size="small" :column="2" style="padding: 5px;margin-top: 5px;">
          <a-descriptions-item label="通行方向">
            {{ channelInfo.direction === 1 ?  '入场' : '出场' }}
          </a-descriptions-item>
          <a-descriptions-item label="识别车牌">
            {{ channelInfo.plate }} ({{ channelInfo.carTypeName }})
          </a-descriptions-item>
          <a-descriptions-item label="当前状态">
            <span v-html="translateState(channelInfo.state)"></span>
          </a-descriptions-item>
          <a-descriptions-item label="通道消息">
            {{ channelInfo.warning }}
          </a-descriptions-item>
          <a-descriptions-item label="通 行 证" >
            {{ currentPassportTypeName }}
            <a-button v-if="channelInfo.passport"
                      type="link"
                      size="small"
                      :icon="showPassport ? 'up' : 'down'"
                      @click="showPassport = !showPassport"/>
          </a-descriptions-item>
          <a-descriptions-item label="通行通道">
            {{ channelInfo.channelName }}
          </a-descriptions-item>
          <a-descriptions-item label="通行区域">
            {{ channelInfo.areaName }}
          </a-descriptions-item>
          <a-descriptions-item label="通行时间">
            {{ channelInfo.passTime && moment(channelInfo.passTime).format('YYYY-MM-DD HH:mm:ss') }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="12" :offset="1" v-if="channelInfo && channelInfo.fee">
        <a-divider orientation="left">费用信息</a-divider>
        <a-descriptions size="small" :column="3" style="padding: 5px;margin-top: 5px;">
          <a-descriptions-item label="应收费用">
            {{ channelInfo.fee.shouldCharge}} 元
          </a-descriptions-item>
          <a-descriptions-item label="实收费用">
            {{ channelInfo.fee.actualCharge  }} 元
          </a-descriptions-item>
          <a-descriptions-item label="折扣费用" >
            {{ channelInfo.fee.discountFee ? (channelInfo.fee.discountFee + '元') : '无' }}
          </a-descriptions-item>
          <a-descriptions-item label="已付费用">
            {{ channelInfo.fee.paidFee ? channelInfo.fee.paidFee : '0' }} 元
          </a-descriptions-item>
          <a-descriptions-item label="入场时间" :span="2">
            {{ channelInfo.fee.enterTime ? moment(channelInfo.fee.enterTime).format('YYYY-MM-DD HH:mm:ss') : '无' }}
          </a-descriptions-item>
          <a-descriptions-item label="停车时长">
            {{ easyReadTime(channelInfo.fee.parkingMinute) }}
          </a-descriptions-item>
          <a-descriptions-item label="计费时长">
            {{ easyReadTime(channelInfo.fee.chargeMinute) }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>
    <Passport v-if="showPassport && this.channelInfo.passport" :passport="this.channelInfo.passport" />
  </div>
</template>

<script>
import moment from "moment";
import Passport from "./Passport";
export default {
  name: "ChannelInfo",
  components: {Passport},
  props: {
    channelInfo: {
      type: Object,
      default: () => undefined
    }
  },
  data () {
    return {
      showPassport: false
    }
  },
  computed: {
    currentPassportTypeName(){
      if(this.channelInfo){
        if(this.channelInfo.passInfo?.length){
          return this.channelInfo.passInfo[0]?.passportTypeName
        }else{
          return this.channelInfo.passportTypeName
        }
      }
      return ''
    }
  },
  methods: {
    moment,
    translateState (state) {
      if (!state) {
        return '无'
      }
      switch (state) {
        case 'PASS_REJECTED':
          return '<span style="color:red">被禁止通行</span>';
        case 'WAIT_CONFIRM':
          return '<span style="color:rgba(255,102,0,0.87)">等待确认放行</span>';
        case 'PASS_VERIFIED_SUCCESS':
          return '<span style="color:rgba(104,241,31,0.87)">放行验证通过，可直接放行</span>';
        case 'CAR_LEFT':
          return '<span style="color:rgba(104,241,31,0.87)">已放行</span>';
        case 'NO_VEHICHLE_EXIST':
          return '<span">通道下无车</span>';
        default:
          return '未知';
      }
    },
    easyReadTime (time) {
      let day = 0
      let hours = 0
      let minute = 0
      let result = ''
      if (time) {
        day = parseInt(time / (24 * 60))
        hours = parseInt((time % (24 * 60)) / 60 )
        minute = time % 60
        if(day > 0) {
          result += day + '天'
        }
        result += hours + '时'
        if (minute) {
          result += minute + '分'
        }
        return  result
      } else {
        return '无'
      }
    }
  }
}
</script>

<style scoped>

</style>